import styles from "./OSWecams.module.scss";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CircularProgress, Divider } from "@mui/material";
import useOSWebcams from "./useOSWebcams";
import OSWebcamListItem from "../OSWebcamListItem/OSWebcamListItem";

const OSWebcams: React.FC = () => {
  const { oSIntelligenceData, webcamDataIsLoading } = useOSWebcams();

  return (
    <div className={styles.oSIntelSidebar}>
      <h2 className={styles.osIntelTitle}>Latest Webcams</h2>
      <Divider sx={{ width: "100%", backgroundColor: "#393C56" }} />
      <LocalizationProvider dateAdapter={AdapterDayjs}></LocalizationProvider>
      {webcamDataIsLoading ? (
        <CircularProgress
          sx={{
            color: "#ffffff",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "40px",
            width: "100%",
          }}
          size={40}
        />
      ) : (
        oSIntelligenceData.map((intelItem) => (
          <OSWebcamListItem osIntelItem={intelItem} key={intelItem.id} />
        ))
      )}
    </div>
  );
};

export default OSWebcams;
