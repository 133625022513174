import { DateTime } from 'luxon';

export const getToolTipDeckGl = ({ layer, object }: any) => {
  return object && layer && layer?.id === "droppedPins" && object.properties
    ? {
        html: `<div>
        <p style='display: flex; align-items: center; gap: 5px; padding: 5px; color:#fff;'>Point: ${object.properties?.title}</p>
        <p style='display: flex; align-items: center; gap: 5px; padding: 5px; color:#fff;'>lat:   ${object.properties?.lat}</p>
        <p style='display: flex; align-items: center; gap: 5px; padding: 5px; color:#fff;'>lng:   ${object.properties?.lng}</p>
       </div> `,
        style: {
          fontSize: "0.8em",
          padding: "15px",
          backgroundColor: "#24263c",
          minWidth: "100px",
          gap: "5px",
        },
      }
    : {
        style: {
          display: "none",
        },
      }; 
};

export const defaultImplementation = () => {
  throw new Error("Function not implemented");
};

export const calculateTimeSince = (timeToCalculateFrom: string): string => {
  const currentUUTCTime = DateTime.utc(); 
  const formatedTime = DateTime.fromISO(timeToCalculateFrom, { zone: 'utc' }); 
  
  if (!formatedTime.isValid) {
    throw new Error('Invalid timestamp format');
  }

  const diff = currentUUTCTime.diff(formatedTime, ['days', 'hours', 'minutes']).toObject();

  const days = diff.days ? Math.floor(diff.days) : 0;
  const hours = diff.hours ? Math.floor(diff.hours) : 0;
  const minutes = diff.minutes ? Math.floor(diff.minutes) : 0;
  const seconds = diff.seconds ? Math.floor(diff.seconds) : 0;

  if (days >= 1) {
    return `${days}d`;
  } else if (hours >= 1) {
    return `${hours}h`;
  } else if (minutes >= 1) {
    return `${minutes}m`;
  } else {
    return `${seconds}s`;
  }
}

export const getTodaysDate = (): string => {
  return DateTime.now().toFormat('yyyy-MM-dd');
}
