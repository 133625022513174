

import React from "react";
import useEmailMfa from "./useEmailMfa";
import styles from "./MFA.module.scss";
import { Box, Typography, TextField, Button } from "@mui/material";



interface EmailMfaProps {
  user: any;
  handleFlip: () => void;
  isFlipped: boolean;
}

const EmailMfa = ({ user, handleFlip, isFlipped }: EmailMfaProps) => {

  const [code, setCode] = React.useState("");
  
  const { emailAuth, resendEmailTime, sendEmailCode, verifyEmailOtp, error } = useEmailMfa(user, code, isFlipped);

  return (
    <div
      style={{ minWidth: "100%" }}
    >
      <form
        className={styles.detailInputWrapper}
      >

        <Box className={styles.inputWrapperMain}>
          <Box className={styles.inputWrapper}>
            <Typography variant="h6"
              className={styles.twoStepVerButton}
            >
              2 - Step Verification
            </Typography>
            <Typography
              className={styles.textMfa}
            >
              Send verification code to your email
            </Typography>
            <div style={{ display: "flex", gap: 10, width: "100%" }}>
              <TextField
                error={error}
                autoComplete="off"
                id="outlined-basic"
                variant="outlined"
                size="small"
                placeholder="Enter code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                sx={{
                  flex: 2,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  '& .MuiInputBase-input::placeholder': {
                    color: 'white',
                  },
                  input: {
                    color: 'white',
                  },
                }}
              />
              {
                resendEmailTime <= 0 &&
                <Button
                  variant="contained"
                  className={styles.buttonWrap}
                  sx={{
                    '&.Mui-disabled': {
                      color: 'black',
                      backgroundColor: '#00a3e3',
                    },
                  }}
                  disabled={resendEmailTime > 0}
                  onClick={sendEmailCode}
                  size={"small"}
                >
                  {emailAuth ? `Resend Code` : `Send Code`}
                </Button>
              }
            </div>
            {error && <Typography
              variant="body2"
              className={styles.errorText}
            >
              Invalid verification code
            </Typography>}
            {emailAuth && <Typography
              variant="body1"
              className={styles.textUser}
            >
              Please input the verification code sent to your email
            </Typography>}
            {
              resendEmailTime > 0 &&
              <Typography
                variant="body2"
                className={styles.resendEmailText}
              >
                Resend code in {resendEmailTime} seconds
              </Typography>
            }
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <Button variant="outlined" onClick={handleFlip}
                sx={{
                  color: "#00a3e3"
                }}
              > {`<- Back`}</Button>
              <Button
                variant="contained"
                className={styles.buttonWrap}
                disabled={!emailAuth}
                onClick={verifyEmailOtp}> Verify</Button>
            </div>
          </Box>
        </Box>

      </form>
    </div>
  );
};



export default EmailMfa;
