import { Box, Button, Divider, Skeleton } from "@mui/material";
import styles from "./Overview.module.scss";
import OveriewShipIcon from "../../../../assets/icons/OveriewShipIcon";
import { AllDetailsTabs } from "../../../../context/useShipDetails";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import moment from "moment";
import Modal from "../../../../components/Modal/Modal";
import useShipClipCoordinates from "./hooks/useShipClipCoordinates";

const Overview = ({
  satelliteImage,
  tabDetails,
  bunkeringValue,
  setBunkeringValue,
  finalPathPoint,
  satelliteImageModal,
  setSatelliteImageModal,
}: {
  satelliteImage: string;
  tabDetails: AllDetailsTabs;
  bunkeringValue: string;
  setBunkeringValue: Dispatch<SetStateAction<string>>;
  finalPathPoint: any;
  satelliteImageModal: boolean;
  setSatelliteImageModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { object_id, detectionData, speeds, dynamic, ship_info, type } =
    tabDetails;

  const shipClipCoordinates = useShipClipCoordinates({ tabDetails });
  let detectionValues = detectionData;

  const isLightOrAttributed = type === "light" || type === "attributed";
  const hasValidDetectionData =
    detectionData && Object.keys(detectionData).length > 0;

  if (
    !isLightOrAttributed ||
    tabDetails.isObservationShip ||
    !hasValidDetectionData
  ) {
    detectionValues = {
      length: tabDetails.length,
      acquired: tabDetails.acquired,
      heading: tabDetails.heading,
      width: tabDetails.width,
      ship_type: tabDetails.ship_type,
    };
  }

  const handleSatelliteImageClick = () => {
    setSatelliteImageModal(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      className={styles.overviewMain}
    >
      {tabDetails.type !== "spoofing" &&
        tabDetails.type !== "sanctioned" &&
        tabDetails.type !== "AISSTS" &&
        tabDetails.type !== "AIS" && (
          <>
            <Box display="flex" gap={2} className={styles.overviewWrap}>
              <Box className={styles.overViewImage}>
                {satelliteImage === "" ? (
                  <Skeleton
                    variant="rounded"
                    width={192}
                    height={192}
                    sx={{ bgcolor: "#393c56" }}
                  />
                ) : tabDetails.bunkering_id ? (
                  <Box
                    sx={{
                      position: "relative",
                      backgroundImage: `url(${satelliteImage})`,
                      height: "192px",
                      width: "192px",
                    }}
                  >
                    <Box
                      style={{
                        position: "absolute",
                        zIndex: 10,
                        transform: `translateX(${shipClipCoordinates.ship1.x}px) translateY(${shipClipCoordinates.ship1.y}px)`,
                      }}
                    >
                      {bunkeringValue === "1" && (
                        <div
                          style={{
                            backgroundColor: "red",
                            height: "4px",
                            width: "4px",
                            opacity: "0.5",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </Box>
                    {/* <img src={BunkeringShip} alt="ShipImg" /> */}
                    <Box
                      style={{
                        position: "absolute",
                        zIndex: 10,
                        transform: `translateX(${shipClipCoordinates.ship2.x}px) translateY(${shipClipCoordinates.ship2.y}px)`,
                      }}
                    >
                      {bunkeringValue === "2" && (
                        <div
                          style={{
                            backgroundColor: "red",
                            height: "4px",
                            width: "4px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                ) : (
                  <img
                    src={satelliteImage}
                    alt="ShipImg"
                    onClick={handleSatelliteImageClick}
                  />
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.overviewDetail}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  className={styles.detailWrap}
                >
                  <span className={styles.detailHead}>OID</span>
                  <span className={styles.detailValue}>
                    {object_id ? object_id : "No Info"}
                  </span>
                </Box>
                <Box display="flex" gap={2}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={styles.detailWrap}
                  >
                    <span className={styles.detailHead}>Latitude</span>
                    <span className={styles.detailValue}>
                      {tabDetails.latitude ?? "No Info"}
                    </span>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={styles.detailWrap}
                  >
                    <span className={styles.detailHead}>Longitude</span>
                    <span className={styles.detailValue}>
                      {tabDetails.longitude ?? "No Info"}
                    </span>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={styles.detailWrap}
                >
                  <span className={styles.detailHead}>Image Captured Time</span>
                  <span className={styles.detailValue}>
                    {moment
                      .utc(detectionValues.acquired)
                      .format("YYYY-MM-DD HH:mm:ss")}
                  </span>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={styles.detailWrap}
                >
                  <span className={styles.detailHead}>Length</span>
                  <span className={styles.detailValue}>
                    {detectionValues.length ?? "No Info"}
                  </span>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={styles.detailWrap}
                >
                  <span className={styles.detailHead}>Width</span>
                  <span className={styles.detailValue}>
                    {detectionValues.width ?? "No Info"}
                  </span>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  className={styles.detailWrap}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={styles.detailWrap}
                  >
                    <span className={styles.detailHead}>Ship Type</span>
                    <span className={styles.detailValue}>
                      {detectionValues.ship_type ?? "No Info"}
                    </span>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={styles.detailWrap}
                  >
                    <span className={styles.detailHead}>Ship Subtype</span>
                    <span className={styles.detailValue}>
                      {ship_info?.ship_subtype ?? "No Info"}
                    </span>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Divider className={styles.overViewDivider} />
            <Box
              display="flex"
              alignItems="flex-end"
              flexDirection="column"
              gap={1}
              className={styles.satelliteImageModal}
            >
              <Modal
                headerTitle={`Satellite Imagery - ${
                  tabDetails?.name ? tabDetails?.name : tabDetails?.object_id
                }`}
                handleClose={() => setSatelliteImageModal(false)}
                onClickMinimize={() => {}}
                onMinimizeClick={!satelliteImageModal}
                showMinimize={false}
              >
                <Box className={styles.modalImage}>
                  {satelliteImage === "" ? (
                    <Skeleton
                      variant="rounded"
                      width={450}
                      height={450}
                      sx={{ bgcolor: "#393c56" }}
                    />
                  ) : (
                    <img
                      src={satelliteImage}
                      alt="ShipImg"
                      className={styles.modalImage}
                    />
                  )}
                </Box>
              </Modal>
            </Box>
          </>
        )}

      <Box
        display="grid"
        gridTemplateColumns="repeat(4,1fr)"
        gap={2}
        className={styles.overviewDetail}
      >
        {tabDetails.type !== "unattributed" && (
          <>
            <Box
              display="flex"
              flexDirection="column"
              gridColumn="span 2"
              className={styles.detailWrap}
            >
              <span className={styles.detailHead}>Latest Speed</span>
              {finalPathPoint ? (
                <span
                  className={styles.detailValue}
                  style={{ fontSize: "10px" }}
                >
                  {finalPathPoint.speed} (Acquired on{" "}
                  {moment
                    .utc(finalPathPoint.timestamp * 1000)
                    .format("YYYY-MM-DD HH:mm:ss")}{" "}
                  UTC)
                </span>
              ) : (
                <span
                  className={styles.detailValue}
                  style={{ fontSize: "10px" }}
                >
                  No Info
                </span>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={styles.detailWrap}
            >
              <span className={styles.detailHead}>Destination</span>
              <span className={styles.detailValue}>
                {dynamic &&
                  dynamic.length > 0 &&
                  dynamic[dynamic.length - 1].destination}
              </span>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={styles.detailWrap}
            >
              <span className={styles.detailHead}>ETA</span>
              <span className={styles.detailValue}>
                {dynamic &&
                dynamic.length > 0 &&
                dynamic[dynamic.length - 1].eta !== null
                  ? moment
                      .utc(dynamic[dynamic.length - 1].eta)
                      .format("YYYY-MM-DD HH:mm:ss")
                  : "No Info"}
              </span>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={styles.detailWrap}
            >
              <span className={styles.detailHead}>Latitude</span>
              <span className={styles.detailValue}>
                {tabDetails.latitude ?? "No Info"}
              </span>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={styles.detailWrap}
            >
              <span className={styles.detailHead}>Longitude</span>
              <span className={styles.detailValue}>
                {tabDetails.longitude ?? "No Info"}
              </span>
            </Box>
          </>
        )}

        <Box
          display="flex"
          flexDirection="column"
          className={styles.detailWrap}
        >
          <span className={styles.detailHead}>Heading</span>
          <span className={styles.detailValue}>
            {tabDetails.heading ?? "No Info"}
          </span>
        </Box>
      </Box>

      {tabDetails.type !== "unattributed" && (
        <>
          <Divider className={styles.overViewDivider} />
          <Box display="flex" alignItems="center" gap={2}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              className={styles.detailWrap}
              width="225px"
            >
              <OveriewShipIcon />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <span className={styles.detailValue}>
                  {dynamic &&
                    dynamic.length > 0 &&
                    dynamic[dynamic.length - 1].draught}
                  m draft
                </span>
                <span className={styles.detailValue}>
                  {tabDetails.length && tabDetails.width
                    ? `${tabDetails.length}m x ${tabDetails.width}m`
                    : "No Info"}
                </span>
              </Box>
            </Box>
            <Box display="grid" gridTemplateColumns="repeat(2,1fr)" gap={2}>
              <Box
                display="flex"
                flexDirection="column"
                gridColumn="span 2"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Type</span>
                <span className={styles.detailValue}>
                  {tabDetails.ship_type ?? "No Info"}
                </span>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Avg. Speed</span>
                <span className={styles.detailValue}>
                  {speeds && speeds.length > 0 ? speeds[5] : "No Info"}
                </span>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Max Speed</span>
                <span className={styles.detailValue}>
                  {speeds && speeds.length > 0 ? speeds[9] : "No Info"}
                </span>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Build Year</span>
                <span className={styles.detailValue}>No Info</span>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Status</span>
                <span className={styles.detailValue}>
                  {tabDetails.status ?? "No Info"}
                </span>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Overview;
