import styles from "./WebcamModal.module.scss";
import { CircularProgress } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import WebcamModalThubmnail from "./WebcamModalThumbnail";
import { useWebcamModal } from "./useWebcamModal";
import { OSInteligenceItem } from "../../SidebarComponents/OSWebcams/model";
import { WebcamHistory } from "./model";

const WebcamModal: React.FC<{
  clickedCoordinates: { x: number; y: number };
  selectedWebcam: OSInteligenceItem;
}> = ({ clickedCoordinates, selectedWebcam }) => {
  const {
    modalRef,
    currentSelectedImage,
    webcamModalIsLoading,
    imageUrl,
    screenShotDate,
    page,
    handlePreviousPage,
    handleNextPage,
    currentThumbnails,
    handleThumbnailImageSelect,
    webcamHistoory,
    getModalPosition,
    imageIsLoading,
  } = useWebcamModal(selectedWebcam);

  return (
    <div
      ref={modalRef}
      className={styles.webcamModal}
      style={{
        left: clickedCoordinates.x,
        top: getModalPosition(clickedCoordinates.y),
      }}
    >
      {webcamModalIsLoading ? (
        <div className={styles.webcamLoadingSpinner}>
          <CircularProgress sx={{ color: "#ffffff" }} size={50} />
        </div>
      ) : (
        <>
          <div className={styles.webCamHeader}>
            <div className={styles.title}>
              Live Cam - {selectedWebcam.title}
            </div>
            <div className={styles.timeSince}>
              {currentSelectedImage.timesSince}
            </div>
          </div>
          {imageIsLoading ? (
            <div className={styles.webcamLoadingSpinner}>
              <CircularProgress sx={{ color: "#ffffff" }} size={50} />
            </div>
          ) : (
            <img
              src={imageUrl}
              className={styles.fullWebcamImage}
              alt="Webcam Image"
            />
          )}
          <div className={styles.webcamInfo}>
            Screenshot updated {screenShotDate}
          </div>
          <div className={styles.webcamThumbnailsContainer}>
            <ArrowBackIosIcon
              sx={{
                cursor: page > 0 ? "pointer" : "default",
                color: page > 0 ? "#fff" : "#7d7d84",
              }}
              onClick={handlePreviousPage}
            />
            <div className={styles.thumbnailCarousel}>
              {currentThumbnails.map((thubmnail: WebcamHistory) => (
                <WebcamModalThubmnail
                  handleThumbnailImageSelect={handleThumbnailImageSelect}
                  thumbNailData={thubmnail}
                  selectedThumbnailImageUrl={
                    currentSelectedImage.selectedThumbnailImage
                  }
                />
              ))}
            </div>
            <ArrowForwardIosIcon
              sx={{
                marginLeft: "2px",
                cursor:
                  (page + 1) * 5 < webcamHistoory.length
                    ? "pointer"
                    : "default",
                color:
                  (page + 1) * 5 < webcamHistoory.length ? "#fff" : "#7d7d84",
              }}
              onClick={handleNextPage}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default WebcamModal;
