export const camcorderMapIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_b_4584_26458)">
<circle cx="20" cy="20" r="20" fill="#111326" fill-opacity="0.75"/>
<circle cx="20" cy="20" r="19.5" stroke="#393C56"/>
</g>
<path d="M27.25 22.3214L25.3375 20.5L27.25 18.6786V22.3214ZM23.5 20.5V22.0714C23.5 22.6845 23.4994 23.0969 23.4721 23.4147C23.4457 23.7227 23.3984 23.8735 23.3435 23.9762C23.2047 24.2356 22.9795 24.4532 22.694 24.5917C22.5734 24.6502 22.4012 24.6973 22.0696 24.7231C21.7303 24.7495 21.2919 24.75 20.65 24.75H16.6C15.9581 24.75 15.5197 24.7495 15.1804 24.7231C14.8488 24.6973 14.6766 24.6502 14.556 24.5917C14.2705 24.4532 14.0453 24.2356 13.9065 23.9762C13.8516 23.8735 13.8043 23.7227 13.7779 23.4147C13.7506 23.0969 13.75 22.6845 13.75 22.0714V18.9286C13.75 18.3155 13.7506 17.9031 13.7779 17.5853C13.8043 17.2773 13.8516 17.1265 13.9065 17.0238C14.0453 16.7644 14.2705 16.5468 14.556 16.4083C14.6766 16.3498 14.8488 16.3027 15.1804 16.2769C15.5197 16.2505 15.9581 16.25 16.6 16.25H20.65C21.2919 16.25 21.7303 16.2505 22.0696 16.2769C22.4012 16.3027 22.5734 16.3498 22.694 16.4083C22.9795 16.5468 23.2047 16.7644 23.3435 17.0238C23.3984 17.1265 23.4457 17.2773 23.4721 17.5853C23.4994 17.9031 23.5 18.3155 23.5 18.9286V20.5Z" fill="white" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<filter id="filter0_b_4584_26458" x="-50" y="-50" width="140" height="140" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="25"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4584_26458"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4584_26458" result="shape"/>
</filter>
</defs>
</svg>`;
