import WebcamThumbnailImage from "../../SidebarComponents/WebcamThumbnailImage/WebcamThumbnailImage";

import styled from "styled-components";
import { WebcamHistory } from "./model";

const ThumbnailContainer = styled.div`
  display: inline;
  cursor: pointer;
`;

const WebcamModalThubmnail: React.FC<{
  handleThumbnailImageSelect: (thubmnail: WebcamHistory) => void;
  thumbNailData: WebcamHistory;
  selectedThumbnailImageUrl: string;
}> = ({
  handleThumbnailImageSelect,
  thumbNailData,
  selectedThumbnailImageUrl,
}) => {
  const selectThubmnailImage = () => {
    handleThumbnailImageSelect(thumbNailData);
  };
  return (
    <ThumbnailContainer onClick={selectThubmnailImage}>
      <WebcamThumbnailImage
        url={thumbNailData.url}
        isActive={selectedThumbnailImageUrl === thumbNailData.docId}
      />
    </ThumbnailContainer>
  );
};

export default WebcamModalThubmnail;
