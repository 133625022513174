import { useEffect, useState } from "react";
import { getWebcamThumbnail } from "./model";

const useWebcamThumbnailImage = ({
  url,
}: {
  url: string;
}): { imageUrl: string; imageIsLoading: boolean } => {
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imageIsLoading, setImageIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchWebcamImage = async () => {
      const jpegData = await getWebcamThumbnail({ url });
      const blobURL = URL.createObjectURL(jpegData);
      setImageUrl(blobURL);
      setImageIsLoading(false);
    };

    fetchWebcamImage();
  }, [url]);

  return { imageUrl, imageIsLoading };
};

export default useWebcamThumbnailImage;
