import {
  FilterParameters,
  ICurrentEventTypeFilterOptions,
} from "../MapComponents/FilterArea/FilterArea";
import { PathStyleExtension } from "@deck.gl/extensions/typed";
import { LineLayer, TextLayer } from "@deck.gl/layers/typed";
import { Dispatch, SetStateAction } from "react";
import { GeoJsonLayer, IconLayer } from "@deck.gl/layers/typed";
import { PathLayer } from "@deck.gl/layers/typed";
import { aisStations } from "./GeoJSONLayers/aisStationData";
import { PathData, ObjectData, AISDarkEvent } from "../MainMap";
import { SHIP_FILTER_PROVIDER } from "../../../utils/Constants";
import {
  calcuateMilesBetweenTwoCoordinates,
  calculateAngle,
  createLayersData,
  getBunkeringImageUrl,
  getSelectedIcon,
  getSelectedShipHeading,
  hexToRgb,
  isPointInPolygon,
  getIconHeading,
} from "./utils";
import {
  BunkeringInterface,
  ShipDetailsInterFace,
} from "../../../context/useShipDetails";
import { LayerToggleSwitches } from "../../../types/types";
import { camcorderMapIcon } from "../../../assets/icons/CamcorderMap";
import { FeatureCollection } from "geojson";
import { OSInteligenceItem } from "../SidebarComponents/OSWebcams/model";

export interface ArrowData {
  position: [number, number];
  angle: number;
  size: number;
  timestamp: number;
}

interface Ship {
  geometry: {
    coordinates: [number, number];
  };
  properties: ShipDetailsInterFace;
} // will move to types

const ICON_MAPPING = {
  marker: { width: 102, height: 191, mask: true },
};

export const filterShips = (
  allShips: any,
  filters: FilterParameters,
  dataType: string,
  forCalculatingShipCount = false
) => {
  const {
    country,
    length,
    markerColor,
    noImoShips,
    sanctionedShips,
    providerFilters,
    type,
    width,
    heading: header,
    isMoving,
    iconStringNormal,
    iconStringSanctioned,
    subType,
    shipSubType,
  } = filters || {};
  let filteredFeatures = allShips.features;
  if (dataType === "spoofing") {
    filteredFeatures = filteredFeatures.filter((ship: any) => {
      const subTypeKey = ship?.properties?.subType;
      return (
        subTypeKey &&
        subType &&
        Object.hasOwn(subType, subTypeKey) &&
        subType[subTypeKey] === true
      );
    });
  }

  if (filteredFeatures && Array.isArray(filteredFeatures)) {
    if (!noImoShips) {
      filteredFeatures = filteredFeatures.filter(
        (ship: any) => ship.properties.imo !== null && ship.properties.imo !== 0
      );
    }
    if (sanctionedShips) {
      filteredFeatures = filteredFeatures.filter(
        (ship: any) => ship.properties.isSanctionedShip
      );
    }
  }

  filteredFeatures = filteredFeatures?.filter((ship: any) => {
    const { length: shipLength, width: shipWidth } = ship.properties;

    if (
      Array.isArray(length) &&
      length.length >= 2 &&
      Array.isArray(width) &&
      width.length >= 2
    ) {
      return (
        shipLength >= length[0] &&
        shipLength <= length[1] &&
        shipWidth >= width[0] &&
        shipWidth <= width[1]
      );
    }
    return false;
  });

  if (header && header.length === 2) {
    filteredFeatures = filteredFeatures?.filter((ship: any) => {
      const { heading: shipHeading } = ship.properties;

      return shipHeading >= header[0] && shipHeading <= header[1];
    });
  }

  // Whats the reason this is string?
  if (isMoving) {
    if (isMoving === "yes") {
      filteredFeatures = filteredFeatures?.filter(
        (ship: any) => ship.properties.moving
      );
    }
    if (isMoving === "no") {
      filteredFeatures = filteredFeatures?.filter(
        (ship: any) => !ship.properties.moving
      );
    }
  }
  if (type && Array.isArray(type) && type.length > 0) {
    // Why are there so much duplicate data in the ship data?
    // Why unattributed different.
    filteredFeatures = filteredFeatures?.filter((ship: any) => {
      if (shipSubType && shipSubType.length) {
        return (
          type.includes(ship.properties.ship_type) &&
          shipSubType.includes(
            dataType === "unattributed"
              ? ship.properties.ship_info.ship_subtype
              : ship.properties.detectionData.ship_info.ship_subtype
          )
        );
      }
      return type.includes(ship.properties.ship_type);
    });
  }

  if (country?.code !== "") {
    filteredFeatures = filteredFeatures?.filter(
      (ship: any) => ship.properties.flag === country?.code
    );
  }

  if (providerFilters && SHIP_FILTER_PROVIDER.includes(dataType)) {
    filteredFeatures = filteredFeatures.filter((ship: any) => {
      const shipData =
        ship?.properties?.detectionData?.provider || ship?.properties?.provider;
      return shipData && providerFilters[shipData.toLowerCase()];
    });
  }

  if (forCalculatingShipCount) return filteredFeatures;
  return {
    type: "FeatureCollection",
    features: filteredFeatures,
    markerColor,
    iconStringNormal,
    iconStringSanctioned,
  };
};

export const getDeckGLLayers = (
  webcamGeoJson: FeatureCollection | null,
  selectWebcam:  (webcamId: OSInteligenceItem, x: number, y: number) => void,
  detections: any,
  zoomLevel: any,
  spoofingEvents: any,
  opticalSTS: any,
  AISSTS: any,
  currentEventTypeFilterOptions: ICurrentEventTypeFilterOptions,
  sanctionedShips: any,
  setSelectedShip: Dispatch<
    SetStateAction<{ [key: string]: ShipDetailsInterFace }>
  >,
  shipDetailTabValue: string | null,
  setShipDetailTabValue: Dispatch<SetStateAction<string | null>>,
  selectedShip: any,
  setShowCursor: any,
  currentCoveraData: any,
  layerToggleSwitches: LayerToggleSwitches,
  setSelectedEvent: Dispatch<
    SetStateAction<{ [key: string]: ShipDetailsInterFace }>
  >,
  selectedEvent: { [key: string]: ShipDetailsInterFace },
  onShipClick: (tabId?: string) => void,
  shipPaths: {
    [key: string]: {
      path: PathData[];
      color: string;
      analysis: boolean;
      dateRange: { startDate: string; endDate: string };
      showPath?: boolean;
      showAllpoints?: boolean;
      showShip?: boolean;
    };
  },
  selectedPath: PathData[],
  playbackValue: number,
  pathRef: React.MutableRefObject<number | null>,
  shipObjects: {
    [key: string]: {
      objects: ObjectData[];
      isBunkeringShip: boolean;
      isEvent: boolean;
    };
  },
  similarShips: {
    [key: string]: ShipDetailsInterFace[];
  },
  showSimilarShips: boolean,
  area: { type: string; coordinates: [[number, number][]] } | null,
  hoveredId: number | null,
  droppedPins: any,
  features: any,
  darkEventOnMap: AISDarkEvent | null,
  showTemporaryCircle: any,
  coordinatesForEstimatedLocationInfo: [number, number] | null,
  handleShipIconClick: (id: string, f: any) => Promise<void>,
  layersForFuturePathPrediction?: any,
  drawRef?: any,
  showDeckglPolygons?: any,
  setShowDeckglPolygons?: any,
  showGlobeView?: any,
  polygonData?: any,
  showShips?: boolean,
  selectedShipEstimatedLoactionLayer?:
    | { startDate: string; data: any }[]
    | null,
  selectedShipEstimatedaLocationPath?: any,
  liveAISShips?: any
) => {
  let layers: any = [];

  aisStations.features = aisStations.features.map((feature: any) => ({
    ...feature,
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(feature.geometry.coordinates[0]),
        parseFloat(feature.geometry.coordinates[1]),
      ],
    },
  }));

  const liveAreaAIS = liveAISShips?.map((point: any, i: any) => ({
    type: "Feature",
    id: i,
    geometry: {
      type: "Point",
      coordinates: [point?.longitude || null, point?.latitude || null],
    },
    properties: {
      ...point,
      type: "AIS",
      timestamp: point.timestamp,
      isSanctionedShip: point?.is_sanctioned_ship,
    },
  }));

  const liveAIS = {
    type: "FeatureCollection",
    features: liveAreaAIS,
  };

  if (
    Object.keys(currentEventTypeFilterOptions).length > 0 &&
    detections &&
    spoofingEvents
  ) {
    const {
      spoofing,
      bunkering,
      AIS,
      light,
      dark,
      unattributed,
      sanctioned,
      similar,
    } = currentEventTypeFilterOptions;

    const filteredLightShips = filterShips(
      detections.lightGeoJson,
      light,
      "light"
    );
    const filteredDarkShips = filterShips(
      detections.attributedGeoJson,
      dark,
      "dark"
    );
    const filteredSpoofingShips = filterShips(
      spoofingEvents,
      spoofing,
      "spoofing"
    );
    const filteredUnattributedShips = filterShips(
      detections.unattributedGeoJson,
      unattributed,
      "unattributed"
    );
    const filteredAISShips = filterShips(liveAIS, AIS, "AIS");

    const selectedShipsArr: any[] = [];
    const observationShipsArr: any[] = [];

    Object.keys(selectedShip).forEach((key) =>
      selectedShipsArr.push({ ...selectedShip[key], selectedShipKey: key })
    );
    Object.keys(selectedEvent).forEach((key) =>
      selectedShipsArr.push({ ...selectedEvent[key], selectedShipKey: key })
    );
    const filteredShipsArr = selectedShipsArr.filter((ship: any) => {
      if (ship.type === "AISSTS") {
        return (
          shipPaths[ship.synmax_ship_id_1]?.showShip === true &&
          shipPaths[ship.synmax_ship_id_2]?.showShip === true
        );
      } else if (ship.type === "opticalSTS") {
        const ship1Show =
          shipPaths[ship.ship1_attribution]?.showShip === true ||
          shipPaths[ship.synmax_ship_id_1]?.showShip === true;
        const ship2Show =
          shipPaths[ship.ship2_attribution]?.showShip === true ||
          shipPaths[ship.synmax_ship_id_2]?.showShip === true;
        if (
          (ship.ship1_attribution === "None" ||
            ship.synmax_ship_id_1.includes("unattributed")) &&
          (ship.ship2_attribution === "None" ||
            ship.synmax_ship_id_2.includes("unattributed"))
        ) {
          return true;
        }
        return ship1Show || ship2Show;
      } else if (ship.type === "unattributed") {
        return true;
      }
      return shipPaths[ship.synmax_ship_id]?.showShip === true;
    });

    Object.keys(selectedShip).forEach((key) => {
      if (
        selectedShip[key].isObservationShip &&
        shipPaths?.[key]?.showShip === true
      ) {
        observationShipsArr.push({
          ...selectedShip[key],
          selectedShipKey: key,
        });
      }
    });

    const selectedShipsGeojson = {
      type: "FeatureCollection",
      features: filteredShipsArr.map((ship: any, i: number) => {
        let coordinates = [
          ship?.detectionData?.longitude || null,
          ship?.detectionData?.latitude || null,
        ];
        if (ship.isPathShip) {
          coordinates = [ship.parentLongitude, ship.parentLatitude];
        } else {
          coordinates = [
            ship?.detectionData?.longitude || ship.longitude,
            ship?.detectionData?.latitude || ship.latitude,
          ];
        }
        return {
          type: "Feature",
          id: i,
          geometry: {
            type: "Point",
            coordinates,
          },
          properties: {
            ...ship,
            isActiveShip: ship.active ? true : false,
          },
        };
      }),
    };

    const similarShipsGeojson = {
      type: "FeatureCollection",
      features: Object.entries(similarShips).flatMap(([key, ships]) =>
        ships.map((ship: ShipDetailsInterFace, i: number) => ({
          type: "Feature",
          id: `${key}-${i}`,
          geometry: {
            type: "Point",
            coordinates: [ship.longitude || null, ship.latitude || null],
          },
          properties: {
            ...ship,
            isHovered: Number(ship.object_id) === hoveredId,
          },
        }))
      ),
    };

    const filteredSimilarShips = filterShips(
      similarShipsGeojson,
      similar,
      "similar"
    );

    const svgWithCircle = `<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29340)" fill-opacity="1"/>
    <path d="M25.0004 35.5H30.824L30.824 30.7413C30.824 20.0159 25.0004 15.5 25.0004 15.5C25.0004 15.5 19.1768 20.0159 19.1768 30.7413L19.1768 35.5H25.0004Z" fill="#FF6D99" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
    <path d="M23 25.5C23 24.5 23.5 23.5 25 23.5C26.5 23.5 27 24.5 27 25.5C27 26.5 26 27 25.5 27.5C25.0547 27.9453 25 28 25 29" stroke="#111326" stroke-width="1.5" stroke-linecap="round"/>
    <circle cx="25" cy="31.5" r="1" fill="#111326"/>
    <defs>
    <radialGradient id="paint0_radial_3218_29340" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
    <stop offset="0.27" stop-color="#00A3E3" stop-opacity="0"/>
    <stop offset="1" stop-color="#00A3E3"/>
    </radialGradient>
    </defs>
    </svg>`;

    const defaultSvg = `<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="#FF6D99" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
    <path d="M4.99951 11.5C4.99951 10.5 5.49951 9.5 6.99951 9.5C8.49951 9.5 8.99951 10.5 8.99951 11.5C8.99951 12.5 7.99951 13 7.49951 13.5C7.0542 13.9453 6.99951 14 6.99951 15" stroke="#111326" stroke-width="1.5" stroke-linecap="round"/>
    <circle cx="6.99951" cy="17.5" r="1" fill="#111326"/>
    </svg>`;

    const observationGeoJson = {
      type: "FeatureCollection",
      features: observationShipsArr.map((ship: any, i: number) => {
        let coordinates = [ship?.longitude || null, ship?.latitude || null];
        return {
          type: "Feature",
          id: i,
          geometry: {
            type: "Point",
            coordinates,
          },
          properties: {
            ...ship,
            isActiveShip: ship.active,
          },
        };
      }),
    };

    const savedPolygons: any = [];

    if (features) {
      Object.keys(features).forEach((key: any) => {
        const feature = features[key];
        if (feature.properties.renderDrawMode === "draw_circle") {
          savedPolygons.push(feature.properties.circlePoints);
        } else {
          savedPolygons.push(feature);
        }
      });
    }

    const createDottedLineFromCoordinates = (
      start: number[],
      end: number[]
    ): { sourcePosition: number[]; targetPosition: number[] }[] => {
      const dotLength = 0.1;
      const gapLength = 0.1;
      const distance = Math.sqrt(
        Math.pow(end[0] - start[0], 2) + Math.pow(end[1] - start[1], 2)
      );
      const numBerOfDotsForDistance = Math.floor(
        distance / (dotLength + gapLength)
      );
      const lineSegments = [];

      for (let i = 0; i < numBerOfDotsForDistance; i++) {
        const startOfDot = (i * (dotLength + gapLength)) / distance;
        const endOfDot = (i * (dotLength + gapLength) + dotLength) / distance;

        lineSegments.push({
          sourcePosition: [
            start[0] + startOfDot * (end[0] - start[0]),
            start[1] + startOfDot * (end[1] - start[1]),
          ],
          targetPosition: [
            start[0] + endOfDot * (end[0] - start[0]),
            start[1] + endOfDot * (end[1] - start[1]),
          ],
        });
      }

      return lineSegments;
    };

    const droppedPoints = {
      type: "FeatureCollection",
      features: droppedPins.map((point: any, i: number) => ({
        type: "Feature",
        id: i,
        geometry: {
          type: "Point",
          coordinates: [point?.lng || 0.01, point?.lat || 0.01],
        },
        properties: {
          ...point,
        },
      })),
    };

    const polygonDataGeojsonFeatures = {
      features: polygonData
        ? polygonData.map((point: any, i: number) => ({
            type: "Feature",
            id: i,
            geometry: {
              type: "Point",
              coordinates: [point?.longitude || null, point?.latitude || null],
            },
            properties: {
              ...point,
              type: "AIS",
              length: point.ship_info?.length,
              width: point.ship_info?.width,
              flag: point.ship_info?.flag,
              imo: point.ship_info?.imo,
              ship_type: point.ship_info?.ship_type,
              isSanctionedShip: point?.is_sanctioned_ship,
            },
          }))
        : [],
    };

    const filteredPolygonDataGeojsonFeatures = filterShips(
      polygonDataGeojsonFeatures,
      AIS,
      "AIS"
    );

    const shipInArea = {
      type: "FeatureCollection",
      features: filteredPolygonDataGeojsonFeatures.features,
    };

    layers = [
      ...layersForFuturePathPrediction,
      new GeoJsonLayer({
        id: "visibcircle",
        filled: true,
        stroked: true,
        pickable: true,
        lineWidthMinPixels: 2,
        getLineColor: [59, 178, 208],
        getFillColor: [59, 178, 208, 35],
        getFillOpacity: 0.3,
        visible: showDeckglPolygons,
        data: {
          type: "FeatureCollection",
          features: savedPolygons,
        },
        onClick: (f: any) => {
          if (!showGlobeView) {
            drawRef.current.set({
              type: "FeatureCollection",
              features: Object.keys(features).map((key) => ({
                ...features[key],
              })),
            });
            setShowDeckglPolygons(false);
          }
        },
      }),
      new GeoJsonLayer({
        id: "droppedPins",
        data: droppedPoints,
        pickable: true,
        pointType: "icon",
        iconAtlas: "./locationPin.svg",
        iconMapping: ICON_MAPPING,
        getIconSize: zoomLevel * 13,
        getIconColor: (f: any) => [255, 0, 0],
        getIcon: (d: any) => "marker",
        onClick: (f: any) => {},
        visible: true,
      }),
      webcamGeoJson !== null && 
      new GeoJsonLayer({
        id: "webcams",
        data: webcamGeoJson,
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 6,
        getIconAngle: 0,
        getIcon: () => ({
          url: "data:image/svg+xml;base64," + btoa(camcorderMapIcon),
          height: 40,
          width: 40,
          mask: false,
        }),
        onClick: (f: any) => {
          selectWebcam(f.object.properties, f.x, f.y);
        },
        visible: layerToggleSwitches.osIntelligence,
      }),
      new GeoJsonLayer({
        id: "polygon",
        data:
          !showSimilarShips && area
            ? shipInArea.features.filter(
                (ship: any) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : shipInArea?.features?.filter((ship: Ship) => {
                const shipPath = shipPaths?.[ship?.properties?.synmax_ship_id];
                return !shipPath || shipPath.showShip !== false;
              }),
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 6,
        getIconAngle: (f: any) => getIconHeading(f.properties.heading || 0),
        getIcon: (f: any) => ({
          url: f.properties?.isSanctionedShip
            ? AIS.iconStringSanctioned
            : AIS.iconStringNormal,
          height: 191,
          width: 102,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            handleShipIconClick("polygon", f);
          })();
        },
        visible: AIS.checked,
      }),
      liveAISShips?.length > 0 &&
        new GeoJsonLayer({
          id: "liveAIS",
          data:
            !showSimilarShips && area
              ? filteredAISShips.features.filter(
                  (ship: any) =>
                    !isPointInPolygon(ship.geometry.coordinates, area)
                )
              : filteredAISShips?.features?.filter((ship: Ship) => {
                  const shipPath =
                    shipPaths?.[ship?.properties?.synmax_ship_id];
                  return !shipPath || shipPath.showShip !== false;
                }),
          pickable: true,
          pointType: "icon",
          getIconSize: zoomLevel * 6,
          getIconAngle: (f: any) => (360 - f.properties.heading || 0) % 360,
          getIcon: (f: any) => ({
            url: f.properties?.isSanctionedShip
              ? AIS.iconStringSanctioned
              : AIS.iconStringNormal,
            height: 191,
            width: 102,
            mask: false,
          }),
          onClick: (f: any) => {
            (async () => {
              handleShipIconClick("liveAIS", f);
            })();
          },
          visible: AIS.checked && showShips,
        }),
      new GeoJsonLayer({
        id: "unattributed",
        data:
          !showSimilarShips && area
            ? filteredUnattributedShips.features.filter(
                (ship: Ship) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : filteredUnattributedShips,
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 6,
        getIconAngle: (f: any) => getIconHeading(f.properties.heading || 0),
        getIcon: () => ({
          url: filteredUnattributedShips.iconStringNormal,
          height: 191,
          width: 102,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            handleShipIconClick("unattributed", f);
          })();
        },
        visible: unattributed.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "coverage-layer",
        data: currentCoveraData,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#4287f5") as any,
        getFillColor: [46, 96, 176],
        getLineWidth: 1,
        opacity: 0.3,
        visible: layerToggleSwitches.coverage,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "AISSTS",
        data:
          !showSimilarShips && area
            ? AISSTS.features.filter(
                (ship: Ship) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : AISSTS?.features?.filter((ship: Ship) => {
                const properties = ship.properties;
                if (!properties) return false;
                const { synmax_ship_id_1, synmax_ship_id_2 } = properties;
                const shipPath1 = synmax_ship_id_1
                  ? shipPaths?.[synmax_ship_id_1]
                  : null;
                const shipPath2 = synmax_ship_id_2
                  ? shipPaths?.[synmax_ship_id_2]
                  : null;
                return (
                  (!shipPath1 || shipPath1.showShip !== false) &&
                  (!shipPath2 || shipPath2.showShip !== false)
                );
              }),
        pickable: true,
        pointType: "icon",
        iconAtlas: "./bunkering/greenGreenNew.svg",
        iconMapping: {
          marker: { height: 100, width: 100, mask: false },
        },
        getIconSize: zoomLevel * 6,
        getIconColor: () => [20, 128, 0] as any,
        getIcon: () => "marker",
        onClick: (f: any) => {
          (async () => {
            handleShipIconClick("AISSTS", f);
          })();
        },
        visible: AIS.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "attributed",
        data:
          !showSimilarShips && area
            ? filteredDarkShips.features.filter(
                (ship: Ship) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : filteredDarkShips?.features?.filter((ship: Ship) => {
                const shipPath =
                  shipPaths?.[ship?.properties?.detectionData?.attribution];
                return !shipPath || shipPath.showShip !== false;
              }),
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 6,
        getIconAngle: (f: any) => getIconHeading(f.properties.heading || 0),
        getIconColor: hexToRgb(filteredDarkShips.markerColor) as any,
        getIcon: (f: any) => ({
          url: f.properties.isSanctionedShip
            ? filteredDarkShips.iconStringSanctioned
            : filteredDarkShips.iconStringNormal,
          height: 191,
          width: 102,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            handleShipIconClick("attributed", f);
          })();
        },
        visible: dark.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "light",
        data:
          !showSimilarShips && area
            ? filteredLightShips.features.filter(
                (ship: Ship) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : filteredLightShips?.features?.filter((ship: Ship) => {
                const shipPath =
                  shipPaths?.[ship?.properties?.detectionData?.attribution];
                return !shipPath || shipPath.showShip !== false;
              }),
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 5,
        getIconAngle: (f: any) => getIconHeading(f.properties.heading || 0),
        getIcon: (f: any) => ({
          url: f.properties.isSanctionedShip
            ? filteredLightShips.iconStringSanctioned
            : filteredLightShips.iconStringNormal,
          height: 191,
          width: 102,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            handleShipIconClick("light", f);
          })();
        },
        visible: light.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "spoofingEvents",
        data:
          !showSimilarShips && area
            ? filteredSpoofingShips.features.filter(
                (ship: Ship) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : filteredSpoofingShips?.features?.filter((ship: Ship) => {
                const shipPath = shipPaths?.[ship?.properties?.synmax_ship_id];
                return !shipPath || shipPath.showShip !== false;
              }),
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 6,
        getIconAngle: (f: any) => getIconHeading(f.properties.heading || 0),
        getIcon: (f: any) => ({
          url: filteredSpoofingShips.iconStringNormal,
          height: 200,
          width: 200,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            handleShipIconClick("spoofingEvents", f);
          })();
        },
        visible: spoofing.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "opticalSTS",
        data:
          !showSimilarShips && area
            ? opticalSTS.features.filter(
                (ship: Ship) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : opticalSTS?.features?.filter((ship: Ship) => {
                const ship1Attribution = ship?.properties?.ship1_attribution;
                const ship2Attribution = ship?.properties?.ship2_attribution;
                if (
                  ship1Attribution === "None" &&
                  ship2Attribution === "None"
                ) {
                  return true;
                }
                const shipPath1 =
                  ship1Attribution && shipPaths?.[ship1Attribution];
                const shipPath2 =
                  ship2Attribution && shipPaths?.[ship2Attribution];
                return (
                  (ship1Attribution === "None" ||
                    !shipPath1 ||
                    shipPath1.showShip !== false) &&
                  (ship2Attribution === "None" ||
                    !shipPath2 ||
                    shipPath2.showShip !== false)
                );
              }),
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 5,
        getIconColor: (f: any) => [] as any,
        getIconAngle: (f: any) =>
          getIconHeading(f.properties.frontend_rotation || 0),
        getIcon: (f: any) => ({
          url: `./bunkering/${getBunkeringImageUrl(f.properties)}`,
          height: 100,
          width: 100,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            handleShipIconClick("opticalSTS", f);
          })();
        },
        visible: bunkering.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "sanctionedShips",
        data:
          !showSimilarShips && area
            ? sanctionedShips.features.filter(
                (ship: Ship) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : sanctionedShips?.features?.filter((ship: Ship) => {
                const shipPath = shipPaths?.[ship?.properties?.synmax_ship_id];
                return !shipPath || shipPath.showShip !== false;
              }),
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 6,
        getIconAngle: (f: any) => getIconHeading(f.properties.heading || 0),
        getIcon: () => ({
          url: sanctioned.iconStringNormal,
          height: 191,
          width: 102,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            handleShipIconClick("sanctionedShips", f);
          })();
        },
        visible: sanctioned.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "selectedShips",
        data:
          !showSimilarShips && area
            ? selectedShipsGeojson.features.filter(
                (ship: any) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : selectedShipsGeojson,
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 15,
        getIconAngle: (f: any) =>
          getIconHeading(getSelectedShipHeading(f.properties) || 0),
        getIcon: (f: any) => getSelectedIcon(shipPaths, f.properties),
        onClick: (f: any) => {
          onShipClick(f?.object?.properties?.synmax_ship_id);
          const shipId = f?.object?.properties?.synmax_ship_id;
          shipId && setShipDetailTabValue(shipId);
          const getPrevShips = (prevShips: any) => {
            const updatedActiveStateShips: any = {};
            Object.keys(prevShips).forEach((key: any) => {
              if (key === shipId) {
                updatedActiveStateShips[key] = {
                  ...prevShips[key],
                  active: true,
                };
              } else {
                updatedActiveStateShips[key] = {
                  ...prevShips[key],
                  active: false,
                };
              }
            });
            return updatedActiveStateShips;
          };
          if (Object.keys(selectedShip).includes(shipId)) {
            setSelectedShip((prevShips) => ({
              ...prevShips,
              ...getPrevShips(prevShips),
            }));
          } else if (Object.keys(selectedEvent).includes(shipId)) {
            setSelectedEvent((prevShips) => ({
              ...prevShips,
              ...getPrevShips(prevShips),
            }));
          }
        },
        visible: true,
      }),
      new GeoJsonLayer({
        id: "observationShips",
        data: observationGeoJson as any,
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 15,
        getIconAngle: (f: any) =>
          getIconHeading(getSelectedShipHeading(f.properties) || 0),
        getIcon: (f: any) => getSelectedIcon(shipPaths, f.properties),
      }),
      new GeoJsonLayer({
        id: "similarShips",
        data: filteredSimilarShips,
        pickable: true,
        pointType: "icon",
        getIconSize: (f: any) =>
          f.properties.isHovered ? zoomLevel * 12 : zoomLevel * 6,
        getIconAngle: (f: any) => getIconHeading(f.properties.heading || 0),
        getIcon: (f: any) => ({
          url: f.properties.isHovered
            ? "data:image/svg+xml;base64," + btoa(svgWithCircle)
            : filteredSimilarShips.iconStringNormal,
          height: f.properties.isHovered ? 200 : 191,
          width: f.properties.isHovered ? 205 : 102,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            handleShipIconClick("similarShips", f);
          })();
        },
        visible: similar?.checked ? true : false,
      }),
    ];

    if (selectedShipEstimatedLoactionLayer !== null) {
      layers.push(
        new GeoJsonLayer({
          id: "estimated-ship-loaction",
          data: selectedShipEstimatedLoactionLayer,
          pickable: true,
          stroked: true,
          filled: true,
          lineWidthMinPixels: 2,
          getLineColor: hexToRgb("#D03527"),
          getFillColor: hexToRgb("#D03527"),
          getLineWidth: 1,
          opacity: 0.3,
          visible: true,
          onHover: (e) => {
            setShowCursor(true);
          },
        })
      );
    }
    if (darkEventOnMap) {
      const { latitude_t0, latitude_t1, longitude_t0, longitude_t1 } =
        darkEventOnMap;

      const path = [
        [longitude_t0, latitude_t0],
        [longitude_t1, latitude_t1],
      ];
      const iconData = [
        {
          position: [longitude_t0, latitude_t0],
          iconString: "Start",
        },
        {
          position: [longitude_t1, latitude_t1],
          iconString: "End",
        },
      ];

      layers.push(
        new PathLayer({
          id: "dark-event-path-layer",
          data: [{ path }],
          getPath: (d) => d.path,
          getColor: [0, 136, 255],
          getWidth: 3,
          widthMinPixels: 2,
          getDashArray: [5, 4],
          parameters: {
            depthTest: false,
          },
          rounded: true,
          extensions: [new PathStyleExtension({ highPrecisionDash: true })],
        }),
        new IconLayer({
          id: "dark-event-icon-layer",
          data: iconData,
          getPosition: (d: any) => d.position,
          getIcon: () => "location-icon",
          getSize: zoomLevel * 10,
          getColor: () => [63, 147, 207],
          iconAtlas: "./locationPin.svg",
          iconMapping: {
            "location-icon": {
              x: 0,
              y: 0,
              width: 94,
              height: 181,
              anchorY: 139,
              mask: true,
            },
          },
          pickable: true,
          onHover: (info: any) => {
            if (info.object) {
              setShowCursor(true);
            } else {
              setShowCursor(false);
            }
          },
        })
      );
    }

    if (
      selectedShipEstimatedaLocationPath.length > 0 &&
      selectedShipEstimatedLoactionLayer !== null
    ) {
      if (coordinatesForEstimatedLocationInfo !== null) {
        const distanceBewteenPoints = calcuateMilesBetweenTwoCoordinates(
          selectedShipEstimatedaLocationPath[0][0],
          selectedShipEstimatedaLocationPath[0][1],
          selectedShipEstimatedaLocationPath[1][0],
          selectedShipEstimatedaLocationPath[1][1]
        );
        const textData = [
          {
            position: coordinatesForEstimatedLocationInfo,
            text: `Distance:${distanceBewteenPoints}`,
          },
        ];

        const estimatedLoacationTextLayer = new TextLayer({
          id: "estimated-loacation-text",
          data: textData,
          getPosition: (d) => d.position,
          getText: (d) => d.text,
          getSize: 12,
          getColor: [255, 255, 255],
          sizeUnits: "pixels",
          billboard: true,
          backgroundPadding: [12, 12],
          fontFamily: "Inter",
          outlineWidth: 2,
          outlineColor: [0, 0, 0],
          backgroundColor: hexToRgb("#24263C"),
        });
        layers.push(estimatedLoacationTextLayer);
      }

      const lineData = createDottedLineFromCoordinates(
        selectedShipEstimatedaLocationPath[0],
        selectedShipEstimatedaLocationPath[1]
      );
      const estimatedLoactionLayer = new LineLayer({
        id: "estimated-location-line",
        data: lineData,
        getSourcePosition: (d) => d.sourcePosition,
        getTargetPosition: (d) => d.targetPosition,
        getColor: () => hexToRgb("#FFFFFF"),
        getWidth: () => 3,
      });

      layers.push(estimatedLoactionLayer);
    }

    Object.keys(shipPaths).forEach((synmax_ship_id) => {
      const pathData = shipPaths[synmax_ship_id].path;
      const showPath = shipPaths[synmax_ship_id]?.showPath ? true : false;
      const arrowData = createLayersData(
        pathData,
        shipPaths[synmax_ship_id].showAllpoints ? true : false,
        zoomLevel
      );
      const pathColor = shipPaths[synmax_ship_id].color;
      const pathAnalysis = shipPaths[synmax_ship_id].analysis;
      showPath &&
        (pathAnalysis
          ? layers.push(
              new IconLayer({
                id: `path-analysis-${synmax_ship_id}`,
                data: pathData,
                getPosition: (d: any) => [d.longitude, d.latitude],
                getIcon: () => "arrow-icon",
                getSize: zoomLevel > 10 ? zoomLevel : 10,
                getAngle: (d: any) => d.angle,
                getColor: () => hexToRgb(pathColor) as any,
                iconAtlas: "./circleIcon.svg",
                iconMapping: {
                  "arrow-icon": {
                    x: 0,
                    y: 0,
                    width: 72,
                    height: 72,
                    mask: true,
                  },
                },
                pickable: true,
                onHover: (info: any) => {
                  if (info.object) {
                    setShowCursor(true);
                  } else {
                    setShowCursor(false);
                  }
                },
              })
            )
          : layers.push(
              new PathLayer({
                id: `path-layer-${synmax_ship_id}`,
                data: [pathData],
                getPath: (d: any) =>
                  d.map((item: any) => [item.longitude, item.latitude]),
                getColor: () => hexToRgb(pathColor),
                getWidth: 3,
                widthMinPixels: 2,
                rounded: true,
                parameters: {
                  depthTest: false,
                },
              }),
              new IconLayer({
                id: `icon-layer-${synmax_ship_id}`,
                data: arrowData,
                getPosition: (d: any) => d.position,
                getIcon: () => "arrow-icon",
                getSize: zoomLevel > 6 ? zoomLevel : 6,
                getAngle: (d: any) => d.angle,
                getColor: () => [255, 255, 255, 255],
                iconAtlas: "./pathArray.svg",
                iconMapping: {
                  "arrow-icon": {
                    x: 0,
                    y: 0,
                    width: 10,
                    height: 6,
                    mask: true,
                  },
                },
              })
            ));
    });

    Object.keys(shipObjects)?.length > 0 &&
      Object.keys(shipObjects).forEach((synmax_ship_id) => {
        const shipObjectsData = shipObjects[synmax_ship_id].objects || [];
        const isBunkeringShip = shipObjects[synmax_ship_id].isBunkeringShip;
        const isEvent = shipObjects[synmax_ship_id].isEvent;
        const showPath = shipPaths[synmax_ship_id]?.showPath ? true : false;

        const iconData = shipObjectsData.map((ship: any) => {
          let iconPath: string | undefined =
            filteredLightShips.iconStringNormal;
          let type: string = "light";
          if (ship.attribution === "None") {
            iconPath = filteredUnattributedShips.iconStringNormal;
            type = "unattributed";
          } else if (ship.attribution && !ship.dark) {
            iconPath = filteredLightShips.iconStringNormal;
            type = "light";
          } else if (ship.attribution && ship.dark) {
            iconPath = filteredDarkShips.iconStringNormal;
            type = "attributed";
          } else if (ship.type === "spoofing") {
            iconPath = filteredSpoofingShips.iconStringNormal;
            type = "spoofing";
          }
          return {
            position: [ship.longitude, ship.latitude],
            angle:
              ship.type === "spoofing" ? 0 : getIconHeading(ship?.heading || 0),
            properties: {
              ...ship,
              iconPath: iconPath || filteredLightShips.iconStringNormal,
              type,
            },
          };
        });
        showPath &&
          layers.push(
            new IconLayer({
              id: `ship-objects-${synmax_ship_id}`,
              data: iconData,
              getPosition: (d: any) => d.position,
              getSize: zoomLevel * 5,
              getAngle: (d: any) => d.angle,
              getIcon: (f: any) => ({
                url: f.properties.iconPath,
                x: 0,
                y: 0,
                width: f.properties.type === "spoofing" ? 200 : 102,
                height: f.properties.type === "spoofing" ? 200 : 191,
                mask: false,
              }),
              pickable: true,
              onClick: (f: any) => {
                const shipId = synmax_ship_id;
                const shipData = f?.object?.properties;
                isBunkeringShip && shipDetailTabValue
                  ? setSelectedShip((prev) => {
                      const currentShipDetails = prev[shipDetailTabValue];
                      if (currentShipDetails) {
                        const updatedDetails = {
                          ...((currentShipDetails as BunkeringInterface)[
                            shipId
                          ] || {}),
                          ...shipData,
                          isPathShip: true,
                        };
                        return {
                          ...prev,
                          [shipDetailTabValue]: {
                            ...(currentShipDetails as BunkeringInterface),
                            [shipId]: updatedDetails,
                          },
                        };
                      }
                      return prev;
                    })
                  : isEvent
                  ? setSelectedEvent((prev) => {
                      const updatedState = {
                        ...prev,
                        [shipId]: {
                          ...prev[shipId],
                          ...shipData,
                          isPathShip: true,
                          parentLongitude:
                            prev[shipId]?.parentLongitude ??
                            prev[shipId]?.longitude,
                          parentLatitude:
                            prev[shipId]?.parentLatitude ??
                            prev[shipId]?.latitude,
                          mmsi: shipData?.mmsi,
                          imo: shipData?.imo,
                          dynamic: [],
                        },
                      };
                      return updatedState;
                    })
                  : setSelectedShip((prev) => {
                      const updatedState = {
                        ...prev,
                        [shipId]: {
                          ...prev[shipId],
                          ...shipData,
                          isPathShip: true,
                          parentLongitude:
                            prev[shipId]?.parentLongitude ??
                            prev[shipId]?.longitude,
                          parentLatitude:
                            prev[shipId]?.parentLatitude ??
                            prev[shipId]?.latitude,
                          object_id: shipData?.object_id,
                          mmsi: shipData?.mmsi,
                          imo: shipData?.imo,
                          dynamic: [],
                        },
                      };
                      return updatedState;
                    });
              },
            })
          );
      });

    if (selectedPath.length > 1 && pathRef.current !== null) {
      const currentPosition = selectedPath[playbackValue] || selectedPath[0];
      const nextPosition = selectedPath[playbackValue + 1] || selectedPath[1];
      const heading = currentPosition?.heading ? currentPosition?.heading : 0;
      layers.push(
        new IconLayer({
          id: "moving-ship",
          data: [
            {
              position: [currentPosition.longitude, currentPosition.latitude],
              angle: calculateAngle(
                [currentPosition.longitude, currentPosition.latitude],
                [nextPosition.longitude, nextPosition.latitude],
                heading
              ),
            },
          ],
          getPosition: (d: any) => d.position,
          getIcon: () => "arrow-icon",
          getSize: zoomLevel * 8,
          getAngle: (d: any) => d.angle,
          getColor: hexToRgb(filteredLightShips.markerColor) as any,
          iconAtlas: "./blueEye.svg",
          iconMapping: {
            "arrow-icon": {
              x: 0,
              y: 0,
              height: 191,
              width: 102,
              mask: true,
            },
          },
        })
      );
    }
  }
  return layers;
};
