import { useState } from "react";
import AttributionList from "./AttributionList/AttributionList";
import ModalAttribution from "./ModalAttribution/ModalAttribution";
import { Box, Button } from "@mui/material";
import styles from "./Attribution.module.scss";
import { AllDetailsTabs } from "../../../../context/useShipDetails";
import { updateShipAttributes } from "../../RenderShips/apiCalls";
import useAttribution from "./hooks/useAttribution";
import useFireBaseAuth from "../../../../context/useFireBaseAuth";

export interface AttributionInterface {
  name?: string;
  length?: number;
  ship_type?: string;
  heading?: number;
  moving?: boolean;
  attribution?: string;
  dark?: boolean;
}

export interface AutoDetails {
  object_id: number;
  attribution: string | null;
  source_id: string;
  provider: string;
  status: string;
  acquired: number;
  dark_time: number | null;
  dark: boolean | null;
  spoofing: boolean | null;
  latitude: number;
  longitude: number;
  length?: number;
  width: number;
  heading?: number;
  ship_type: string;
  moving?: boolean;
  sts: boolean;
  ship_info: any | null;
}

export interface ManualDetails {
  dark?: boolean;
  heading?: number;
  length?: number;
  moving?: boolean;
  attribution?: string;
  ship_type: string;
}

export interface AllDetailsTabsAttribution extends AllDetailsTabs {
  auto?: AutoDetails;
  manual?: ManualDetails;
}

const checkObjectValues = (obj: any) => {
  for (let key in obj) {
    if (key !== "object_id" && obj[key] !== null) {
      return false;
    }
  }
  return true;
};


const manualAttributionHeader = (manualAtt: any, role: any) => {
  if (!manualAtt && role === "theia_customer") {
    return false;
  }
  return true
}

const showAutoAttribution = (manualAtt: any, role: any) => {

  if (manualAtt && role === "theia_customer") {
    return false;
  }
  return true;
}
const Attribution = ({
  tabDetails,
}: {
  tabDetails: AllDetailsTabsAttribution;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [activeCall, setActiveCall] = useState(false);
  const [updatedAttribution, setUpdatedAttribution] = useState(null);
  const { currentUser } = useFireBaseAuth();

  const autoAttribution = useAttribution({
    details: tabDetails,
    type: "auto",
    updatedAttribution: undefined,
  });
  const manualAttribution = useAttribution({
    details: tabDetails,
    type: "manual",
    updatedAttribution: updatedAttribution,
  });

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const handleSave = async (attribution: any) => {
    if (tabDetails.object_id) {
      try {
        setActiveCall(true);
        const response: any = await updateShipAttributes(tabDetails.object_id, {
          ...manualAttribution.attributes,
          ...attribution,
        });
        setUpdatedAttribution(response?.data?.manual);

        handleClose();
      } catch (e) {
        console.error(e);
      }
      setActiveCall(false);
    }
  };

  const removeAttribution = async () => {
    if (tabDetails.object_id) {
      try {
        setActiveCall(true);
        const response: any = await updateShipAttributes(tabDetails.object_id, {
          object_id: tabDetails.object_id,
          attribution: null,
        });
        setUpdatedAttribution(response?.data?.manual);
      } catch (e) {
        console.error(e);
      }
      setActiveCall(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={styles.attributionMain}
    >
      {showAutoAttribution(manualAttribution.attributes, currentUser.role) &&  <><span className={styles.attributionHead}>Automatic Attribution</span>
      <AttributionList attribution={autoAttribution.attributes} />
      </>
      }
      {manualAttributionHeader(manualAttribution.attributes, currentUser.role)  && <span className={styles.attributionHead}>Manual Attribution</span>}

      {checkObjectValues(manualAttribution.attributes) ? (
        currentUser.role !== "theia_customer" ? (
          <Box>
            <Button
              className={styles.attributionButton}
              variant="outlined"
              onClick={handleOpen}
            >
              Add Manual Attribution
            </Button>
          </Box>
        ) : null
      ) : (
        <Box>
          <AttributionList attribution={manualAttribution.attributes} />
          {currentUser.role !== "theia_customer" && (
            <Box
              display="grid"
              gridTemplateColumns="repeat(2,1fr)"
              gap={2}
              marginTop={2}
            >
              <Button
                className={styles.attributionButton}
                variant="outlined"
                onClick={handleOpen}
              >
                Update
              </Button>
              <Button
                className={styles.attributionButton}
                variant="outlined"
                onClick={() => removeAttribution()}
              >
                Remove
              </Button>
            </Box>
          )}
        </Box>
      )}

      <ModalAttribution
        openManualAttributionModal={showModal}
        handleSave={handleSave}
        handleClose={handleClose}
        ManualAttribution={manualAttribution.attributes}
        activeCall={activeCall}
      />
    </Box>
  );
};

export default Attribution;
