import { createContext, useContext, useState } from "react";
import { defaultImplementation } from "../utils/utilis";
import { LayerToggleSwitches } from "../types/types";
import { Dayjs } from "dayjs";
import { RightSideNavigationSelectedFilter } from "../pages/Theia/MainMap";

interface IGlobalFiltersProviderProps {
  children: React.ReactNode;
}

const DEFAULT_LAYER_TOGGLE: LayerToggleSwitches = {
  osIntelligence: false,
  territories: false,
  ports: false,
  infrastructure: false,
  submarineCablesVisible: false,
  coverage: false,
  highRisk: false,
};

interface IGlobalFiltersContext {
  layerToggleSwitches: LayerToggleSwitches;
  showCursor: boolean;
  setShowCursor: React.Dispatch<React.SetStateAction<boolean>>;
  onLayerToggle: (key: keyof LayerToggleSwitches) => void;
  date: Dayjs | null;
  setDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  currentSelectedRightNavBar: RightSideNavigationSelectedFilter | null;
  onCurrentSelectedRightNavBarChange: (
    selectedFilter?: RightSideNavigationSelectedFilter
  ) => void;
}

const GlobalFiltersContext = createContext<IGlobalFiltersContext>({
  layerToggleSwitches: DEFAULT_LAYER_TOGGLE,
  showCursor: false,
  setShowCursor: defaultImplementation,
  onLayerToggle: defaultImplementation,
  date: null,
  setDate: defaultImplementation,
  currentSelectedRightNavBar: null,
  onCurrentSelectedRightNavBarChange: defaultImplementation,
});

const useGlobalFilters = () => {
  const context = useContext<IGlobalFiltersContext>(GlobalFiltersContext);
  if (!context) {
    throw new Error(
      "useGlobalFilters must be used with a GlobalFiltersProvider"
    );
  }
  return context;
};

export const GlobalFiltersProvider = ({
  children,
}: IGlobalFiltersProviderProps) => {
  const [showCursor, setShowCursor] = useState<boolean>(false);
  const [layerToggleSwitches, setLayerToggleSwitches] =
    useState<LayerToggleSwitches>(DEFAULT_LAYER_TOGGLE);
  const [date, setDate] = useState<Dayjs | null>(null);
  const [currentSelectedRightNavBar, setCurrentSelectedRightNavBar] =
    useState<RightSideNavigationSelectedFilter | null>(null);

  const onCurrentSelectedRightNavBarChange = (
    selectedFilter?: RightSideNavigationSelectedFilter
  ) => {
    setCurrentSelectedRightNavBar(selectedFilter ? selectedFilter : null);
  };

  const onLayerToggle = (key: keyof LayerToggleSwitches) => {
    setLayerToggleSwitches((prevState: LayerToggleSwitches) => {
      return {
        ...prevState,
        [key]: !prevState[key],
      };
    });
  };
  return (
    <GlobalFiltersContext.Provider
      value={{
        layerToggleSwitches,
        showCursor,
        setShowCursor,
        onLayerToggle,
        date,
        setDate,
        currentSelectedRightNavBar,
        onCurrentSelectedRightNavBarChange,
      }}
    >
      {children}
    </GlobalFiltersContext.Provider>
  );
};

export default useGlobalFilters;
