import CircularProgress from "@mui/material/CircularProgress";
import useWebcamThumbnailImage from "./useWebcamThumbnailImage";
import styled from "styled-components";

const WebcamLoadingSpinner = styled.div`
  width: 46px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WebcamThumbnail = styled.img`
  height: 42px;
  width: 42px;
  border-radius: 4px;
`;

const WebcamThumbnailImage: React.FC<{ url: string; isActive?: boolean }> = ({
  url,
  isActive,
}) => {
  const { imageUrl, imageIsLoading } = useWebcamThumbnailImage({ url });

  return (
    <>
      {imageIsLoading ? (
        <WebcamLoadingSpinner>
          <CircularProgress sx={{ color: "#ffffff" }} size={12} />
        </WebcamLoadingSpinner>
      ) : (
        <WebcamThumbnail
          src={imageUrl}
          alt="Webcam Image"
          style={{
            ...(isActive && {
              border: "1px solid #FFF",
            }),
          }}
        />
      )}
    </>
  );
};

export default WebcamThumbnailImage;
