import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { updateAllProjects } from "../../../../common/Common.store";
import { setChangeSaved } from "../../Theia.store";
import ShipFilters from "../ShipFilters/ShipFilters";
import FilterEventType from "../FilterEventType/FilterEventType";
import { SHIP_MARKERS_EVENTS } from "../../../../utils/Constants";
import ListenForOutsideClicks from "../../../../common/Listen";
import {
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Radio,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Close, ExpandMore } from "@mui/icons-material";
import styles from "./FilterArea.module.scss";
import { FilterLayer, RightSideNavigationSelectedFilter } from "../../MainMap";
import LightView from "../../../../assets/LightView.png";
import DarkMap from "../../../../assets/icons/DarkMap";
import SatelliteMap from "../../../../assets/icons/SatelliteMap";
import {
  getColorUpdatedSanctionedShipIcon,
  getColorUpdatedShipIcon,
} from "../../../../assets/icons/ShipFilterIcons";
import useGlobalFilters from "../../../../context/useGlobalFilter";

export interface ICurrentEventTypeFilterOptions {
  [key: string]: FilterParameters;
}
export interface FilterParameters {
  checked: boolean;
  noInfoShips: boolean;
  noImoShips: boolean;
  sanctionedShips: boolean;
  markerColor: string;
  iconStringNormal?: string;
  iconStringSanctioned?: string;
  length: number[];
  width: number[];
  heading: number[];
  country: { code: string; label: string };
  subType: { [key: string]: boolean };
  type: string[];
  providerFilters: {
    [key: string]: boolean;
  };
  isMoving?: string;
  shipSubType?: string[];
}
interface IFilterAreaProps {
  currentEventTypeFilterOptions: ICurrentEventTypeFilterOptions;
  setCurrentEventTypeFilterOptions: any;
  filterLayers: FilterLayer[];
  showFilter: boolean;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setMapStyle: React.Dispatch<React.SetStateAction<string>>;
  showGlobeView: boolean;
  isSimilarShips: boolean;
}

const FilterArea = (props: IFilterAreaProps) => {
  const {
    currentEventTypeFilterOptions,
    setCurrentEventTypeFilterOptions,
    filterLayers,
    showFilter,
    setShowFilter,
    setMapStyle,
    showGlobeView,
    isSimilarShips,
  } = props;
  const dispatch = useDispatch();
  const { currentSelectedRightNavBar, onCurrentSelectedRightNavBarChange } =
    useGlobalFilters();
  const { allProjects } = useSelector((state: RootState) => state.common);
  const [currentEventTypeFilter, setCurrentEventTypeFilter] = useState<
    string | null
  >(null);
  const { tabs, selectedTabId } = useSelector(
    (state: RootState) => state.header
  );
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const [listening, setListening] = useState(false);
  const menuRef: any = useRef(null);
  const mapStyles = {
    dark: "mapbox://styles/mapbox/dark-v11",
    satellite: "mapbox://styles/mapbox/satellite-streets-v12",
    light: "mapbox://styles/mapbox/light-v11",
  };

  const [selectedStyle, setSelectedStyle] = useState(mapStyles.light);

  useEffect(() => {
    const handleOutsideClick = ListenForOutsideClicks({
      listening,
      setListening,
      menuRef,
      handleOutsideClick: () => {
        setCurrentEventTypeFilter(null);
        onCurrentSelectedRightNavBarChange();
      },
    });

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [
    listening,
    setListening,
    menuRef,
    currentSelectedRightNavBar,
    onCurrentSelectedRightNavBarChange,
    setCurrentEventTypeFilter,
  ]);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      event.stopPropagation();
      setExpanded(isExpanded ? panel : false);
    };

  const currentFilter =
    currentEventTypeFilterOptions[
      currentEventTypeFilter ? currentEventTypeFilter : ""
    ];

  const handleUpdateShipFilters = (
    updatedFilterState: ICurrentEventTypeFilterOptions
  ) => {
    const { folderId, workStationId } = tabs[selectedTabId];

    // set color properties for icons
    if (currentEventTypeFilter) {
      updatedFilterState[currentEventTypeFilter].iconStringNormal =
        getColorUpdatedShipIcon(
          currentEventTypeFilter,
          updatedFilterState[currentEventTypeFilter].markerColor
        );
      updatedFilterState[currentEventTypeFilter].iconStringSanctioned =
        getColorUpdatedSanctionedShipIcon(
          currentEventTypeFilter,
          updatedFilterState[currentEventTypeFilter].markerColor
        );
      if (currentEventTypeFilter) {
        setCurrentEventTypeFilterOptions(updatedFilterState);
      }
      const updatedProjects = {
        ...allProjects,
        [folderId]: {
          ...allProjects[folderId],
          workStations: {
            ...allProjects[folderId].workStations,
            [workStationId]: {
              ...allProjects[folderId].workStations[workStationId],
              mapState: {
                ...allProjects[folderId].workStations[workStationId].mapState,
                mapFilters: updatedFilterState,
              },
            },
          },
        },
      };
      dispatch(setChangeSaved(false));
      dispatch(updateAllProjects(updatedProjects));
    }
  };

  const updateCheckedFilterValue = (eventType: any, oldValue: any) => {
    const { folderId, workStationId } = tabs[selectedTabId];

    const updatedFilterState = {
      ...currentEventTypeFilterOptions,
      [eventType]: {
        ...currentEventTypeFilterOptions[eventType],
        checked: !oldValue,
      },
    };
    setCurrentEventTypeFilterOptions(updatedFilterState);
    // dispatch for state update
    const updatedProjects = {
      ...allProjects,
      [folderId]: {
        ...allProjects[folderId],
        workStations: {
          ...allProjects[folderId].workStations,
          [workStationId]: {
            ...allProjects[folderId].workStations[workStationId],
            mapState: {
              ...allProjects[folderId].workStations[workStationId].mapState,
              mapFilters: updatedFilterState,
            },
          },
        },
      },
    };
    dispatch(setChangeSaved(false));
    dispatch(updateAllProjects(updatedProjects));
  };

  const handleMapClick = (style: string) => {
    setMapStyle(style);
    setSelectedStyle(style);
  };

  return (
    <Box display="flex" flexDirection="column" className={styles.filtersMain}>
      {currentSelectedRightNavBar === RightSideNavigationSelectedFilter.Ships &&
        Object.keys(currentEventTypeFilterOptions).length > 0 && (
          <Box className={styles.filterSelector}>
            {currentEventTypeFilter ? (
              <ShipFilters
                currentEventTypeFilter={currentEventTypeFilter}
                setCurrentEventTypeFilter={setCurrentEventTypeFilter}
                handleUpdateShipFilters={handleUpdateShipFilters}
                currentFilter={currentFilter}
                currentEventTypeFilterOptions={currentEventTypeFilterOptions}
                setCurrentEventTypeFilterOptions={
                  setCurrentEventTypeFilterOptions
                }
              />
            ) : (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className={styles.shipFiltersHead}
                >
                  <span>Ship Marker</span>
                  <Close
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowFilter(!showFilter);
                      setCurrentEventTypeFilter(null);
                      onCurrentSelectedRightNavBarChange();
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  className={styles.mapCheckbox}
                >
                  <Checkbox
                    size="medium"
                    className={styles.checkboxMain}
                    classes={{ checked: styles.checkboxChecked }}
                  />
                  <span className={styles.mapCheckboxLabel}>
                    Show Legend on Map
                  </span>
                </Box>
                {SHIP_MARKERS_EVENTS.map((items, key) => {
                  return (
                    <Accordion
                      disabled={items.disabled}
                      expanded={expanded === key}
                      onChange={handleChange(key)}
                      key={key}
                      className={styles.accordionMain}
                      classes={{ expanded: styles.accordionExpanded }}
                    >
                      <AccordionSummary
                        aria-controls={"panel1-content"}
                        id={"panel1-content"}
                        className={styles.accordionWrap}
                        classes={{
                          content: styles.accordionContent,
                        }}
                        expandIcon={
                          <ExpandMore className={styles.expandMoreIcon} />
                        }
                      >
                        <Radio
                          className={styles.radioMain}
                          classes={{ checked: styles.radioChecked }}
                          checked={expanded === key}
                        />
                        <span className={styles.accordionLabel}>
                          {items.EventName}
                        </span>
                      </AccordionSummary>

                      <AccordionDetails className={styles.accordionDetail}>
                        {key === 0 && (
                          <FilterEventType
                            updateCheckedFilterValue={updateCheckedFilterValue}
                            currentEventTypeFilterOptions={
                              currentEventTypeFilterOptions
                            }
                            setCurrentEventTypeFilter={
                              setCurrentEventTypeFilter
                            }
                            isSimilarShips={isSimilarShips}
                          />
                        )}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </>
            )}
          </Box>
        )}
      {currentSelectedRightNavBar ===
        RightSideNavigationSelectedFilter.Layers && (
        <Box className={styles.filterSelector}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={styles.shipFiltersHead}
          >
            <span>Map Layers</span>
            <Close
              onClick={(e) => {
                e.stopPropagation();
                onCurrentSelectedRightNavBarChange();
                setCurrentEventTypeFilter(null);
              }}
            />
          </Box>

          <Box className={styles.mapLayersWrap}>
            <Box
              className={styles.LayerContentWrap}
              onClick={() => handleMapClick(mapStyles.dark)}
            >
              <Box
                className={`${styles.mapLayer} ${
                  selectedStyle === mapStyles.dark ? styles.selectedBox : ""
                }`}
              >
                <DarkMap />
              </Box>
              <Typography className={styles.mapTitle}>Dark</Typography>
            </Box>

            <Box
              className={`${styles.LayerContentWrap} ${
                showGlobeView ? styles.disabledWrap : ""
              }`}
              onClick={() =>
                showGlobeView ? {} : handleMapClick(mapStyles.satellite)
              }
            >
              <Box
                className={`${styles.mapLayer} ${
                  selectedStyle === mapStyles.satellite && !showGlobeView
                    ? styles.selectedBox
                    : ""
                }`}
              >
                <SatelliteMap />
              </Box>
              <Typography className={styles.mapTitle}>Satellite</Typography>
            </Box>

            <Box
              className={styles.LayerContentWrap}
              onClick={() => handleMapClick(mapStyles.light)}
            >
              <Box
                className={`${styles.mapLayer} ${
                  selectedStyle === mapStyles.light ||
                  (selectedStyle === mapStyles.satellite && showGlobeView)
                    ? styles.selectedBox
                    : ""
                }`}
              >
                <img
                  className={styles.LightImg}
                  src={LightView}
                  alt="LightView"
                />
              </Box>
              <Typography className={styles.mapTitle}>Light</Typography>
            </Box>
          </Box>
          <Box className={styles.layerSelector}>
            {filterLayers.map((item) => (
              <FormControlLabel
                key={item.id}
                className={styles.layerRow}
                control={
                  <Checkbox
                    checked={item.value}
                    onChange={item.onChange}
                    size="medium"
                    sx={{ color: "white" }}
                  />
                }
                label={item.label}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export function useDebounce(cb: any, delay: any) {
  const [debounceValue, setDebounceValue] = useState(cb);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(cb);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [cb, delay]);
  return debounceValue;
}

export default FilterArea;
