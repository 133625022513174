import { api } from "../../../../services/api";

export interface OSInteligenceItem {
  description: string;
  id: string;
  latitude: number;
  longitude: number;
  osint_type: string;
  source_id: string;
  timestamp: string;
  title: string;
  lastUpdated: string;
}

export const getAllWebcams = async ({ date }: { date: string }): Promise<OSInteligenceItem[]> => {
  try {
    const res = await api.get(`osIntel/webcams?date=${date}`);
    return res.data;
  } catch(error: any) {
    throw new Error("error fetching Open Source Iinteligence:", error);
  }
};
