import React, { useState, useEffect } from "react";
import { api } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { User } from "firebase/auth";
import { useSnackbar } from "../../context/SnackbarContext";

const useEmailMfa = (user: User | null, mfaCode: string, isFlipped: boolean) => {

    // State to record if email code was send
    const [emailAuth, setEmailAuth] = useState<boolean>(false);

    // State to set resend email code time
    const [resendEmailTime, setResendEmailTime] = useState<number>(0);

    const [error, setError] = useState<boolean>(false);

    // navigation function
    const navigate = useNavigate();

    // snackbar context
    const { setNewSnackBar } = useSnackbar();


    useEffect(() => {
        if (mfaCode.length === 0 && error) {
            setError(false);
        }
    }, [mfaCode, error])

    // Function to send email code
    const sendEmailCode = async (): Promise<void> => {

        // Send email code to user
        const response = await api.post("/mfa/send-email-code", {
            email: user?.email,
            uid: user?.uid,
        });

        // Set snackbar message and open snackbar
        setNewSnackBar({ message: response.data, severity: "success" });
        setEmailAuth(true);
        // Set resend email time to 60 seconds
        setResendEmailTime(60);

    }

    const verifyEmailOtp = async (): Promise<void> => {

        // Check if user exists
        if (user) {
            try {

                // Verify email code
                const response = await api.post("/mfa/verify-email-code", {
                    uid: user?.uid,
                    token: mfaCode,
                    email: user?.email,
                });

                // If email code is valid, set isMFAVerified to true and navigate to theia
                if (response.data.valid) {
                    localStorage.setItem("isMFAVerified", "true");
                    navigate("/theia");
                }
                console.log(
                    response.data.valid ? "Login successful!" : "Login failed."
                );
            } catch (error) {
                setError(true);
                console.error("Error logging in:", error);
            }
        }
    };


    useEffect(() => {

        if (!isFlipped) {
            setTimeout(() => {
                setEmailAuth(false);
                setResendEmailTime(0);
                // State to set resend email code time
                console.log("unmounting emailMfa");
            }, 1000);
        }
    }, [isFlipped])


    // Use effect to set resend email time
    useEffect(() => {
        // If resend email time is 0, return
        if (resendEmailTime === 0) return;

        // Set up a timer that ticks every second
        const intervalId = setInterval(() => {
            setResendEmailTime((prevTime) => prevTime - 1);
        }, 1000);

        // Clear interval on component unmount or when timeLeft reaches 0
        return () => clearInterval(intervalId);

    }, [resendEmailTime]);

    // Return necessary functions and states
    return { emailAuth, resendEmailTime, sendEmailCode, verifyEmailOtp, error };
};


export default useEmailMfa;