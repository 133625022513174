import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { defaultImplementation } from "../utils/utilis";
import {
  getObjectsForShips,
  getSanctionVessels,
  getShipAttributes,
  getShipInfo,
  getShipPath,
} from "../pages/Theia/RenderShips/apiCalls";
import useSatelliteImageTimeline from "../pages/Theia/ShipDetails/hooks/useSatelliteImageTimeline";
import { AISDarkEvent, ShipImages } from "../pages/Theia/MainMap";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { getShipStatusByType } from "../pages/Theia/utils";

interface DynamicData {
  synmax_ship_id: string;
  timestamp: number;
  mmsi?: number;
  imo?: number;
  name?: string;
  call_sign?: string;
  destination?: string;
  eta?: number;
  length?: number;
  width?: number;
  draught?: number;
}

export interface ShipDetailsInterFace {
  synmax_ship_id: string;
  name?: string;
  synmax_ship_id_1?: string;
  synmax_ship_id_2?: string;
  object_id_1?: number;
  object_id_2?: number;
  type?: string;
  active?: boolean;
  aisclass?: string;
  call_sign?: string;
  commercialowner?: string;
  count?: number;
  dimensions_a?: number;
  dimensions_b?: number;
  dimensions_c?: number;
  dimensions_d?: number;
  effectivecontrol?: string;
  financialowner?: string;
  flag?: string;
  imo?: number;
  last_query?: number;
  length?: number;
  mmsi?: number;
  primaryoperator?: string;
  registeredowner?: string;
  ship_subtype?: string;
  ship_type?: string;
  shiptype_enhanced?: string;
  speeds?: number[];
  technicalmanager?: string;
  typename?: string;
  width?: number;
  object_id?: number;
  attribution?: string;
  source_id?: string;
  provider?: string;
  status?: string;
  acquired?: number;
  dark_time?: number;
  dark?: boolean;
  spoofing?: boolean;
  longitude?: number;
  latitude?: number;
  heading?: number;
  frontend_rotation?: number;
  moving?: any;
  sts?: any;
  ship1_status?: string;
  ship2_status?: string;
  isSanctionedShip?: boolean;
  detectionData?: any;
  dynamic?: DynamicData[];
  sanctions_list?: any;
  isOpticalBunkering?: any;
  isShip1?: any;
  isObservationShip?: boolean;
  isPathShip?: boolean;
  pathColor?: string;
  parentLatitude?: any;
  parentLongitude?: any;
  bunkering_id?: string;
  ship1_attribution?: string;
  ship2_attribution?: string;
  detection_ship_subtype?: string;
  ship_info?: {
    ship_subtype?: string;
  };
}

export interface AllDetailsTabs extends ShipDetailsInterFace {
  tabType: "ship" | "port" | "satelliteImage" | "event"; // will update later
}

export type BunkeringInterface = AllDetailsTabs & {
  [key: string]: AllDetailsTabs;
};

export interface ShipDetail {
  dynamic: DynamicData[];
  [key: string]: string | number | DynamicData[] | undefined;
}

export const updateActiveStatus = (
  object: { [key: string]: ShipDetailsInterFace | BunkeringInterface },
  activeShipId: string
) => {
  return Object.keys(object).reduce((acc, key) => {
    acc[key] = {
      ...object[key],
      active: key === activeShipId,
    };
    return acc;
  }, {} as { [key: string]: ShipDetailsInterFace | BunkeringInterface });
};

export interface PathData {
  synmax_ship_id: string;
  timestamp: number;
  heading: number | null;
  speed: number;
  longitude: number;
  latitude: number;
}

export interface ObjectData {
  object_id: number;
  attribution: string;
  source_id?: string;
  provider?: string;
  status?: string;
  acquired?: number;
  dark_time?: number;
  dark?: boolean;
  spoofing?: boolean;
  longitude?: number;
  latitude?: number;
  length?: number;
  width?: number;
  heading?: number;
  ship_type?: string;
  moving?: boolean;
  sts?: boolean;
  aoi?: string | null;
}

interface ShipDetailsContextProps {
  detailsTabs: AllDetailsTabs[];
  setDetailsTabs: (newState: AllDetailsTabs[]) => void;
  shipDetailTabValue: string | null;
  setShipDetailTabValue: Dispatch<SetStateAction<string | null>>;
  selectedShip: { [key: string]: ShipDetailsInterFace | BunkeringInterface };
  setSelectedShip: Dispatch<
    SetStateAction<{
      [key: string]: ShipDetailsInterFace | BunkeringInterface;
    }>
  >;
  shipDetails: { [key: string]: ShipDetail };
  setShipDetails: Dispatch<
    SetStateAction<{
      [key: string]: ShipDetail;
    }>
  >;
  fetchAndSetShipDetails: (myShip: {
    synmax_ship_id: string;
    object_id?: number;
    type?: string;
    lat?: number;
    lng?: number;
    synmax_ship_id_1?: string;
    synmax_ship_id_2?: string;
    object_id_1?: number;
    object_id_2?: number;
    ship1_status?: string;
    ship2_status?: string;
    name?: string;
    pathColor?: string;
  }) => void;
  selectedEvent: { [key: string]: ShipDetailsInterFace | BunkeringInterface };
  setSelectedEvent: Dispatch<
    SetStateAction<{
      [key: string]: ShipDetailsInterFace | BunkeringInterface;
    }>
  >;
  shipPaths: {
    [key: string]: {
      path: PathData[];
      color: string;
      analysis: boolean;
      dateRange: { startDate: string; endDate: string };
      showPath?: boolean;
      showAllpoints?: boolean;
      showShip?: boolean;
    };
  };
  setShipPaths: Dispatch<
    SetStateAction<{
      [key: string]: {
        path: PathData[];
        color: string;
        analysis: boolean;
        dateRange: { startDate: string; endDate: string };
        showPath?: boolean;
        showAllpoints?: boolean;
        showShip?: boolean;
      };
    }>
  >;
  handleShipDetailsTabChange: any;
  shipObjects: {
    [key: string]: {
      objects: ObjectData[];
      isBunkeringShip: boolean;
      isEvent: boolean;
      parentShipObject: number;
    };
  };
  setShipObjects: Dispatch<
    SetStateAction<{
      [key: string]: {
        objects: ObjectData[];
        isBunkeringShip: boolean;
        isEvent: boolean;
        parentShipObject: number;
      };
    }>
  >;
  bunkeringValue: string;
  setBunkeringValue: Dispatch<SetStateAction<string>>;
  fetchAndSetShipPath: any;
  handleShipDetailsTabClose: (tabId: string, tabType: string) => void;
  fetchAndSetEventDetails: any;
  fetchEvents: any;
  fetchShipPaths: any;
  updateActiveStatus: (
    object: { [key: string]: ShipDetailsInterFace | BunkeringInterface },
    activeShipId: string
  ) => any;
  setDarkEventOnMap: Dispatch<SetStateAction<AISDarkEvent | null>>;
  darkEventOnMap: AISDarkEvent | null;
  collapsed: boolean;
  setSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
  shipCollapsed: boolean;
  setShipCollapsed: Dispatch<SetStateAction<boolean>>;
  active: number;
  setActive: Dispatch<SetStateAction<number>>;
  fetchShips: (
    myShips: {
      synmax_ship_id: string;
      object_id: number;
      type: string;
      lat?: number;
      lng?: number;
      synmax_ship_id_1?: string;
      synmax_ship_id_2?: string;
      object_id_1?: number;
      object_id_2?: number;
      ship1_status?: string;
      ship2_status?: string;
      name?: string;
      path: { startDate: string; endDate: string; color: string };
      path2?: { startDate: string; endDate: string; color: string };
      heading: number;
    }[],
    activeShipId: string | null
  ) => void;
  fetchSatellites: (
    mySatellite: {
      synmax_ship_id: string;
      name: string;
    }[],
    activeTabId: string | null
  ) => void;
}

const ShipDetailsContext = createContext<ShipDetailsContextProps>({
  detailsTabs: [],
  setDetailsTabs: defaultImplementation,
  shipDetailTabValue: null,
  setShipDetailTabValue: defaultImplementation,
  selectedShip: {},
  setSelectedShip: defaultImplementation,
  shipDetails: {},
  setShipDetails: defaultImplementation,
  fetchAndSetShipDetails: defaultImplementation,
  selectedEvent: {},
  setSelectedEvent: defaultImplementation,
  shipPaths: {},
  setShipPaths: defaultImplementation,
  handleShipDetailsTabChange: defaultImplementation,
  shipObjects: {},
  setShipObjects: defaultImplementation,
  bunkeringValue: "1",
  setBunkeringValue: defaultImplementation,
  fetchAndSetShipPath: defaultImplementation,
  handleShipDetailsTabClose: defaultImplementation,
  fetchAndSetEventDetails: defaultImplementation,
  fetchEvents: defaultImplementation,
  fetchShipPaths: defaultImplementation,
  updateActiveStatus: defaultImplementation,
  darkEventOnMap: null,
  setDarkEventOnMap: defaultImplementation,
  collapsed: false,
  setSidebarCollapsed: defaultImplementation,
  shipCollapsed: false,
  setShipCollapsed: defaultImplementation,
  active: 0,
  setActive: defaultImplementation,
  fetchShips: defaultImplementation,
  fetchSatellites: defaultImplementation,
});

const useShipDetails = () => {
  const context = useContext<ShipDetailsContextProps>(ShipDetailsContext);
  if (!context) {
    throw new Error("useShipDetail must be used with a ShipDetailsProvider");
  }
  return context;
};

export const ShipDetailsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [collapsed, setSidebarCollapsed] = useState<boolean>(true);
  const [shipCollapsed, setShipCollapsed] = useState<boolean>(true);
  const [active, setActive] = useState<number>(0);
  const [detailsTabs, setDetailsTabs] = useState<AllDetailsTabs[]>([]);
  const [shipDetailTabValue, setShipDetailTabValue] = useState<string | null>(
    null
  );
  const [selectedShip, setSelectedShip] = useState<{
    [key: string]: ShipDetailsInterFace | BunkeringInterface;
  }>({});

  const [shipDetails, setShipDetails] = useState<{ [key: string]: ShipDetail }>(
    {}
  );

  const [selectedEvent, setSelectedEvent] = useState<{
    [key: string]: ShipDetailsInterFace | BunkeringInterface;
  }>({});
  const [darkEventOnMap, setDarkEventOnMap] = useState<AISDarkEvent | null>(
    null
  );
  const { selectedTabId, tabs } = useSelector(
    (state: RootState) => state.header
  );

  useEffect(() => {
    if (selectedTabId) {
      if (Object.keys(tabs).length > 0) {
        setSelectedEvent({});
        setSelectedShip({});
        setShipPaths({});
        setShipObjects({});
      }
    }
  }, [selectedTabId]);

  const [shipPaths, setShipPaths] = useState<{
    [key: string]: {
      path: PathData[];
      color: string;
      analysis: boolean;
      dateRange: { startDate: string; endDate: string };
      showPath?: boolean;
      showAllpoints?: boolean;
      showShip?: boolean;
    };
  }>({});

  const updateSelectedState = (synmaxShipId: string) => {
    setSelectedShip((prevSelectedShip) => ({
      ...updateActiveStatus(prevSelectedShip, synmaxShipId),
    }));
    setSelectedEvent((prevSelectedEvents) => ({
      ...updateActiveStatus(prevSelectedEvents, synmaxShipId),
    }));
  };

  const fetchAndSetEventDetails = async (synmaxShipId: string) => {
    const existingShipData = selectedEvent[synmaxShipId];
    if (existingShipData && Object.keys(existingShipData).length > 10) {
      updateSelectedState(synmaxShipId);
      return;
    }
    if (synmaxShipId.startsWith("sat:")) {
      const name = satelliteImageTimeline?.[synmaxShipId]?.[0]?.name || "";
      const id = synmaxShipId.slice(4);
      await shouldFetchSatelliteImage(id, name);
      return;
    }
    const shipDetails = await getShipInfo([synmaxShipId], null);
    setShipDetails((prev) => ({
      ...prev,
      ...shipDetails,
    }));
    setSelectedEvent((prevSelected) => ({
      ...updateActiveStatus(prevSelected, synmaxShipId),
      [synmaxShipId]: {
        ...prevSelected[synmaxShipId],
        ...shipDetails[synmaxShipId],
        active: true,
      },
    }));

    setSelectedShip((prevSelected) => ({
      ...updateActiveStatus(prevSelected, synmaxShipId),
    }));
  };

  const [bunkeringValue, setBunkeringValue] = useState<string>("1");
  const [shipObjects, setShipObjects] = useState<{
    [key: string]: {
      objects: ObjectData[];
      isBunkeringShip: boolean;
      isEvent: boolean;
      parentShipObject: number;
    };
  }>({});

  const fetchAndSetShipDetails = async (myShip: {
    synmax_ship_id: string;
    object_id?: number;
    type?: string;
    lat?: number;
    lng?: number;
    synmax_ship_id_1?: string;
    synmax_ship_id_2?: string;
    object_id_1?: number;
    object_id_2?: number;
    ship1_status?: string;
    ship2_status?: string;
    name?: string;
    pathColor?: string;
  }) => {
    const {
      synmax_ship_id,
      object_id,
      type,
      lat,
      lng,
      synmax_ship_id_1,
      synmax_ship_id_2,
      object_id_1,
      object_id_2,
      ship1_status,
      ship2_status,
    } = myShip || {};
    const existingShipData = selectedShip[synmax_ship_id];

    if (existingShipData) {
      if (
        existingShipData.type === "opticalSTS" ||
        existingShipData.type === "AISSTS"
      ) {
        for (const key in existingShipData) {
          const data = existingShipData as Record<string, any>;
          if (
            data[key] &&
            typeof data[key] === "object" &&
            Object.keys(data[key]).length > 10
          ) {
            updateSelectedState(synmax_ship_id);
            return;
          }
        }
      } else {
        if (Object.keys(existingShipData).length > 10) {
          updateSelectedState(synmax_ship_id);
          return;
        }
      }
    }

    const updatedShip: {
      [key: string]: ShipDetailsInterFace | BunkeringInterface;
    } = {};

    if (type === "AISSTS") {
      if (synmax_ship_id_1 && synmax_ship_id_2) {
        const shipDetails = await getShipInfo(
          [synmax_ship_id_1, synmax_ship_id_2],
          null
        );
        setShipDetails((prev) => ({
          ...prev,
          ...shipDetails,
        }));
        updatedShip[synmax_ship_id] = {
          synmax_ship_id,
          synmax_ship_id_1,
          synmax_ship_id_2,
          type,
          latitude: lat,
          longitude: lng,
          ship1_status: "AIS",
          ship2_status: "AIS",
          [synmax_ship_id_1]: {
            ...shipDetails[synmax_ship_id_1],
            type,
          },
          [synmax_ship_id_2]: {
            ...shipDetails[synmax_ship_id_2],
            type,
          },
        };
      }
    } else if (type === "opticalSTS") {
      if (synmax_ship_id_1 && synmax_ship_id_2) {
        const ship1Attributes = await getShipAttributes(object_id_1 as number);
        const ship2Attributes = await getShipAttributes(object_id_2 as number);
        const ship1Details =
          !synmax_ship_id_1.includes("unattributed") &&
          (await getShipInfo([synmax_ship_id_1], null));
        setShipDetails((prev) => ({
          ...prev,
          ...ship1Details,
        }));
        const ship2Details =
          !synmax_ship_id_2.includes("unattributed") &&
          (await getShipInfo([synmax_ship_id_2], null));
        setShipDetails((prev) => ({
          ...prev,
          ...ship2Details,
        }));
        updatedShip[synmax_ship_id] = {
          ...ship1Attributes,
          synmax_ship_id,
          synmax_ship_id_1,
          synmax_ship_id_2,
          type,
          ship1_status,
          ship2_status,
          object_id_1,
          object_id_2,
          frontend_rotation:
            ship1Attributes?.heading || ship2Attributes?.heading || 0,
          [synmax_ship_id_1]: {
            ...ship1Attributes,
            ...ship1Details[synmax_ship_id_1],
            type,
          },
          [synmax_ship_id_2]: {
            ...ship2Attributes,
            ...ship2Details[synmax_ship_id_2],
            type,
          },
        };
      }
    } else {
      const shipDetails =
        !synmax_ship_id.includes("unattributed") &&
        (await getShipInfo([synmax_ship_id], null));
      setShipDetails((prev) => ({
        ...prev,
        ...shipDetails,
      }));
      const additionalData = object_id && (await getShipAttributes(object_id));

      updatedShip[synmax_ship_id] = {
        ...shipDetails[synmax_ship_id],
        ...(additionalData && Object.keys(additionalData).length > 0
          ? additionalData
          : {}),
      };
    }
    setSelectedShip((prevShips) => ({
      ...updateActiveStatus(prevShips, synmax_ship_id),
      [synmax_ship_id]: {
        ...prevShips[synmax_ship_id],
        ...updatedShip[synmax_ship_id],
        active: true,
      },
    }));

    setSelectedEvent((prevSelectedEvents) => ({
      ...prevSelectedEvents,
      ...updateActiveStatus(prevSelectedEvents, synmax_ship_id),
    }));
  };
  const isEventTab = (synmax_ship_id: string) =>
    Object.keys(selectedEvent).includes(synmax_ship_id);
  const {
    satelliteImageTimeline,
    shouldFetchSatelliteImage,
    setSatelliteImageTimeline,
  } = useSatelliteImageTimeline();

  useEffect(() => {
    const getNewShipDetails = () => {
      const satelliteTimelineTabs = Object.keys(satelliteImageTimeline).length
        ? Object.values(satelliteImageTimeline).map(
            (satellite: ShipImages[]) => ({
              synmax_ship_id: satellite[0]?.id,
              name: satellite[0]?.name,
              tabType: "satelliteImage" as const,
            })
          )
        : [];
      const shipTabs = Object.keys(selectedShip).length
        ? Object.values(selectedShip).map((ship) => ({
            ...ship,
            tabType: "ship" as const,
          }))
        : [];

      const eventTabs = Object.keys(selectedEvent).length
        ? Object.values(selectedEvent).map((event) => ({
            ...event,
            tabType: "event" as const,
          }))
        : [];
      return [...shipTabs, ...eventTabs, ...satelliteTimelineTabs];
    };
    setDetailsTabs(getNewShipDetails());
  }, [selectedShip, satelliteImageTimeline, selectedEvent]);

  const handleShipDetailsTabChange = async (
    event?: React.SyntheticEvent,
    newValue?: string
  ) => {
    if (!newValue) return;
    setShipDetailTabValue(newValue);
    setBunkeringValue("1");
    if (newValue.startsWith("sat:")) {
      if (
        satelliteImageTimeline.hasOwnProperty(newValue) &&
        satelliteImageTimeline[newValue].length > 1
      )
        return;
      const name = satelliteImageTimeline?.[newValue]?.[0]?.name || "";
      const id = newValue.slice(4);
      await shouldFetchSatelliteImage(id, name);
      return;
    }
    const startDate = shipPaths?.[newValue]?.dateRange?.startDate;
    const endDate = shipPaths?.[newValue]?.dateRange?.endDate;
    const color = shipPaths?.[newValue]?.color;
    const similarShipTabIsSelected = newValue.startsWith("sim:");

    const isEvent = isEventTab(newValue);
    if (isEvent) {
      await fetchAndSetEventDetails(newValue);
      await fetchAndSetShipPath([newValue], startDate, endDate, color, true);
    } else if (!similarShipTabIsSelected) {
      const selectedTab = selectedShip[newValue];
      const shipObject = {
        synmax_ship_id: newValue,
        object_id: selectedTab?.object_id,
        type: selectedTab?.type,
        lat: selectedTab?.latitude,
        lng: selectedTab?.longitude,
        synmax_ship_id_1: selectedTab?.synmax_ship_id_1,
        synmax_ship_id_2: selectedTab?.synmax_ship_id_2,
        object_id_1: selectedTab?.object_id_1,
        object_id_2: selectedTab?.object_id_2,
        ship1_status: selectedTab?.ship1_status,
        ship2_status: selectedTab?.ship2_status,
        name: selectedTab?.name,
        pathColor: selectedTab?.pathColor,
      };
      await fetchAndSetShipDetails(shipObject);
      const shipTypesToInclude = ["light", "attributed", "sanctioned"];
      if (shipObject.type === "opticalSTS" || shipObject.type === "AISSTS") {
        if (
          shipObject.synmax_ship_id_1 &&
          shipPaths[shipObject.synmax_ship_id_1]
        ) {
          await fetchAndSetShipPath(
            [shipObject.synmax_ship_id_1],
            shipPaths?.[shipObject.synmax_ship_id_1]?.dateRange?.startDate,
            shipPaths?.[shipObject.synmax_ship_id_1]?.dateRange?.endDate,
            shipPaths?.[shipObject.synmax_ship_id_1]?.color,
            false,
            true,
            shipObject?.object_id_1
          );
        }
        if (
          shipObject.synmax_ship_id_2 &&
          shipPaths[shipObject.synmax_ship_id_2]
        ) {
          await fetchAndSetShipPath(
            [shipObject.synmax_ship_id_2],
            shipPaths?.[shipObject.synmax_ship_id_2]?.dateRange?.startDate,
            shipPaths?.[shipObject.synmax_ship_id_2]?.dateRange?.endDate,
            shipPaths?.[shipObject.synmax_ship_id_2]?.color,
            false,
            true,
            shipObject?.object_id_2
          );
        }
      } else if (
        shipObject?.type &&
        shipTypesToInclude.includes(shipObject?.type)
      ) {
        await fetchAndSetShipPath([newValue], startDate, endDate, color);
      }
    }
  };

  const fetchAndSetShipPath = async (
    shipIds: string[],
    start: string,
    end: string,
    color: string,
    isEventShip?: boolean,
    isBunkeringShip?: boolean,
    objectId?: number
  ) => {
    const shipIdsToFetch = shipIds
      .filter((id) => {
        const existingPathData = shipPaths[id];
        return !(
          existingPathData &&
          existingPathData.path &&
          existingPathData.path.length > 0
        );
      })
      .filter((id) => !id.includes("unattributed"));

    if (shipIdsToFetch.length > 0) {
      const allShipPath: PathData[] = await getShipPath(
        shipIdsToFetch,
        start,
        end
      );
      setShipPaths((prevShipPaths) => {
        const updatedShipPaths = { ...prevShipPaths };
        shipIdsToFetch.forEach((shipId) => {
          if (!updatedShipPaths[shipId]) {
            updatedShipPaths[shipId] = {
              path: [],
              color,
              analysis: false,
              dateRange: { startDate: start, endDate: end },
              showShip: true,
            };
          }
        });
        allShipPath.forEach((data) => {
          updatedShipPaths[data.synmax_ship_id].path.push(data);
        });
        return updatedShipPaths;
      });
      const pathObjects = await getObjectsForShips(shipIds, start, end);
      setShipObjects((prev) => ({
        ...prev,
        [shipIds[0]]: {
          ...prev[shipIds[0]],
          objects: pathObjects,
          isBunkeringShip: isBunkeringShip ? true : false,
          isEvent: isEventShip ? true : false,
          parentShipObject: objectId ? +objectId : 0,
        },
      }));
    }
  };

  const handleShipDetailsTabClose = async (tabId: string, tabType: string) => {
    let activeShipId: string | null = null;
    setShipDetailTabValue(null);
    const allTabIds = detailsTabs.map((tab) => tab.synmax_ship_id);
    const indexToDelete = allTabIds.indexOf(tabId);
    if (indexToDelete !== -1) {
      allTabIds.splice(indexToDelete, 1);
      if (allTabIds.length > 0) {
        activeShipId =
          indexToDelete > 0 ? allTabIds[indexToDelete - 1] : allTabIds[0];
        setShipDetailTabValue(activeShipId);
      } else {
        setShipDetailTabValue(null);
      }
    }
    if (tabId.includes("_") && !selectedShip[tabId]?.isObservationShip) {
      const shipIds = selectedShip[tabId];
      const synmax_ship_id1 = shipIds?.synmax_ship_id_1 || "";
      const synmax_ship_id2 = shipIds?.synmax_ship_id_2 || "";
      const {
        [synmax_ship_id1]: removedPath1,
        [synmax_ship_id2]: removedPath2,
        ...remainingShipPaths
      } = shipPaths;
      setShipPaths(remainingShipPaths);
    } else {
      const { [tabId]: removedShipPath, ...restShipsPath } = shipPaths;
      const { [tabId]: removedShipObject, ...restShipObjects } = shipObjects;
      setShipPaths(restShipsPath);
      setShipObjects(restShipObjects);
    }

    if (darkEventOnMap?.synmax_ship_id === tabId) {
      setDarkEventOnMap(null);
    } else if (tabId.includes("_")) {
      const shipIds = selectedShip[tabId];
      const synmax_ship_id1 = shipIds?.synmax_ship_id_1 || "";
      const synmax_ship_id2 = shipIds?.synmax_ship_id_2 || "";
      if (
        darkEventOnMap &&
        (darkEventOnMap.synmax_ship_id === synmax_ship_id1 ||
          darkEventOnMap.synmax_ship_id === synmax_ship_id2)
      ) {
        setDarkEventOnMap(null);
      }
    }

    if (tabType === "ship") {
      const { [tabId]: removedShip, ...updatedSelectedShip } = selectedShip;
      activeShipId
        ? setSelectedShip(updateActiveStatus(updatedSelectedShip, activeShipId))
        : setSelectedShip({ ...updatedSelectedShip });
      activeShipId
        ? setSelectedEvent(updateActiveStatus(selectedEvent, activeShipId))
        : setSelectedEvent((prev) => ({ ...prev }));
    } else if (tabType === "satelliteImage") {
      const { [tabId]: removedSatellite, ...updatedSatelliteImages } =
        satelliteImageTimeline;
      setSatelliteImageTimeline({ ...updatedSatelliteImages });
    } else if (tabType === "event") {
      const { [tabId]: removedEvent, ...updatedEvents } = selectedEvent;
      activeShipId
        ? setSelectedEvent(updateActiveStatus(updatedEvents, activeShipId))
        : setSelectedEvent({ ...updatedEvents });
      activeShipId
        ? setSelectedShip(updateActiveStatus(selectedShip, activeShipId))
        : setSelectedShip((prev) => ({ ...prev }));
    }
  };

  const fetchEvents = async (
    myEvents: {
      synmax_ship_id: string;
      type: string;
      lat?: number;
      lng?: number;
      path: { startDate: string; endDate: string; color: string };
      name?: string;
    }[],
    activeShipId: string | null
  ) => {
    if (!myEvents || myEvents.length === 0) return;

    const eventsToFetch = myEvents.filter(
      (event) => !selectedEvent.hasOwnProperty(event.synmax_ship_id)
    );

    const activeShipInFetchList = myEvents.find(
      (ship) => ship.synmax_ship_id === activeShipId
    );

    if (activeShipInFetchList && activeShipId) {
      // if (tabId) {
      handleShipDetailsTabChange(undefined, activeShipId);
      if (activeShipId.startsWith("sat:")) {
        const name = satelliteImageTimeline?.[activeShipId]?.[0]?.name || "";
        const id = activeShipId.slice(4);
        shouldFetchSatelliteImage(id, name);
      }
      setSelectedEvent((prevSelected) => ({
        ...updateActiveStatus(prevSelected, activeShipId),
        [activeShipId]: {
          ...prevSelected[activeShipId],
          ...shipDetails[activeShipId],
          active: true,
        },
      }));
      // }
      await fetchAndSetEventDetails(activeShipId);
      await fetchAndSetShipPath(
        [activeShipId],
        activeShipInFetchList?.path?.startDate,
        activeShipInFetchList?.path?.endDate,
        activeShipInFetchList?.path?.color,
        true
      );
    }

    const updatedEvent: {
      [key: string]: ShipDetailsInterFace;
    } = {};

    const synmaxShipIds = eventsToFetch.map((event) => event.synmax_ship_id);

    try {
      for (const shipId of synmaxShipIds) {
        const shipObject = eventsToFetch.find(
          (event) => event.synmax_ship_id === shipId
        );
        if (shipObject) {
          updatedEvent[shipId] = {
            name: shipObject?.name || "",
            synmax_ship_id: shipObject.synmax_ship_id,
            type: shipObject.type,
            ...(shipObject?.lat && shipObject?.lng
              ? { latitude: shipObject.lat, longitude: shipObject.lng }
              : {}),
            pathColor: shipObject?.path?.color,
          };
        }
      }
      setSelectedEvent((prevEvent) => ({
        ...prevEvent,
        ...updatedEvent,
      }));
    } catch (error) {
      console.error("Error fetching events info: ", error);
    }
  };

  const fetchShipPaths = async (
    shipPathsData: {
      synmax_ship_id: string;
      pathColor: string;
      analysis: boolean;
      dateRange: { startDate: string; endDate: string };
    }[]
  ) => {
    setShipPaths((prevShipPaths) => {
      const allShipsData = shipPathsData.reduce(
        (
          acc: {
            [key: string]: {
              path: PathData[];
              color: string;
              analysis: boolean;
              dateRange: { startDate: string; endDate: string };
              showShip?: boolean;
            };
          },
          ship
        ) => {
          acc[ship.synmax_ship_id] = {
            path:
              prevShipPaths?.[ship.synmax_ship_id]?.path?.length > 0
                ? prevShipPaths[ship.synmax_ship_id]?.path
                : [],
            color: ship.pathColor || "#FFF",
            analysis: ship.analysis,
            dateRange: ship.dateRange,
            showShip: true,
          };
          return acc;
        },
        {}
      );
      return allShipsData;
    });
  };

  //Initially default selected Tab value
  useEffect(() => {
    const fetchInitialShipDetails = async () => {
      if (shipDetailTabValue === null && detailsTabs.length > 0) {
        const firstTab = detailsTabs[0];
        const firstTabKey = detailsTabs[0].synmax_ship_id;
        if (!firstTabKey) return;
        if (
          detailsTabs[0].tabType === "ship" ||
          detailsTabs[0].tabType === "event"
        ) {
          setShipDetailTabValue(firstTabKey);
          const startDate = shipPaths?.[firstTabKey]?.dateRange?.startDate;
          const endDate = shipPaths?.[firstTabKey]?.dateRange?.endDate;
          const color = shipPaths?.[firstTabKey]?.color;
          const isEvent = isEventTab(firstTabKey);
          if (isEvent) {
            await fetchAndSetEventDetails(firstTabKey);
            await fetchAndSetShipPath(
              [firstTabKey],
              startDate,
              endDate,
              color,
              true
            );
          } else {
            const shipObject = {
              synmax_ship_id: firstTabKey,
              object_id: firstTab?.object_id,
              type: firstTab?.type,
              lat: firstTab?.latitude,
              lng: firstTab?.longitude,
              synmax_ship_id_1: firstTab?.synmax_ship_id_1,
              synmax_ship_id_2: firstTab?.synmax_ship_id_2,
              object_id_1: firstTab?.object_id_1,
              object_id_2: firstTab?.object_id_2,
              ship1_status: firstTab?.ship1_status,
              ship2_status: firstTab?.ship2_status,
              name: firstTab?.name,
              pathColor: firstTab?.pathColor,
            };
            await fetchAndSetShipDetails(shipObject);
            const shipTypesToInclude = ["light", "attributed", "sanctioned"];
            if (
              shipObject.type === "opticalSTS" ||
              shipObject.type === "AISSTS"
            ) {
              if (
                shipObject.synmax_ship_id_1 &&
                shipPaths[shipObject.synmax_ship_id_1]
              ) {
                await fetchAndSetShipPath(
                  [shipObject.synmax_ship_id_1],
                  shipPaths?.[shipObject.synmax_ship_id_1]?.dateRange
                    ?.startDate,
                  shipPaths?.[shipObject.synmax_ship_id_1]?.dateRange?.endDate,
                  shipPaths?.[shipObject.synmax_ship_id_1]?.color,
                  false,
                  true,
                  shipObject?.object_id_1
                );
              }
              if (
                shipObject.synmax_ship_id_2 &&
                shipPaths[shipObject.synmax_ship_id_2]
              ) {
                await fetchAndSetShipPath(
                  [shipObject.synmax_ship_id_2],
                  shipPaths?.[shipObject.synmax_ship_id_2]?.dateRange
                    ?.startDate,
                  shipPaths?.[shipObject.synmax_ship_id_2]?.dateRange?.endDate,
                  shipPaths?.[shipObject.synmax_ship_id_2]?.color,
                  false,
                  true,
                  shipObject?.object_id_2
                );
              }
            } else if (
              shipObject?.type &&
              shipTypesToInclude.includes(shipObject?.type)
            ) {
              await fetchAndSetShipPath(
                [firstTabKey],
                startDate,
                endDate,
                color
              );
            }
          }
        }
      }
    };
    fetchInitialShipDetails();
  }, [detailsTabs]);

  const fetchShips = async (
    myShips: {
      synmax_ship_id: string;
      object_id: number;
      type: string;
      lat?: number;
      lng?: number;
      synmax_ship_id_1?: string;
      synmax_ship_id_2?: string;
      object_id_1?: number;
      object_id_2?: number;
      ship1_status?: string;
      ship2_status?: string;
      name?: string;
      path: { startDate: string; endDate: string; color: string };
      path2?: { startDate: string; endDate: string; color: string };
      heading: number;
    }[],
    activeShipId: string | null
  ) => {
    const vesselsData = await getSanctionVessels();
    if (!myShips || myShips.length === 0) return;

    const activeShipInFetchList = myShips.find(
      (ship) => ship.synmax_ship_id === activeShipId
    );
    const shipTypesToInclude = ["light", "attributed", "sanctioned"];
    if (activeShipInFetchList && activeShipId) {
      await fetchAndSetShipDetails(activeShipInFetchList);
      if (
        activeShipInFetchList.type === "opticalSTS" ||
        activeShipInFetchList.type === "AISSTS"
      ) {
        if (
          activeShipInFetchList.path &&
          activeShipInFetchList.synmax_ship_id_1
        ) {
          await fetchAndSetShipPath(
            [activeShipInFetchList.synmax_ship_id_1],
            activeShipInFetchList?.path?.startDate,
            activeShipInFetchList?.path?.endDate,
            activeShipInFetchList?.path?.color,
            false,
            true,
            activeShipInFetchList?.object_id_1
          );
        }

        if (
          activeShipInFetchList?.path2 &&
          activeShipInFetchList.synmax_ship_id_2
        ) {
          await fetchAndSetShipPath(
            [activeShipInFetchList.synmax_ship_id_2],
            activeShipInFetchList?.path2?.startDate,
            activeShipInFetchList?.path2?.endDate,
            activeShipInFetchList?.path2?.color,
            true,
            false,
            activeShipInFetchList?.object_id_2
          );
        }
      } else if (
        activeShipInFetchList?.type &&
        shipTypesToInclude.includes(activeShipInFetchList?.type)
      ) {
        await fetchAndSetShipPath(
          [activeShipInFetchList.synmax_ship_id],
          activeShipInFetchList?.path?.startDate,
          activeShipInFetchList?.path?.endDate,
          activeShipInFetchList?.path?.color
        );
      }
    }

    const updatedShip: { [key: string]: any } = {};

    const shipsToFetch = myShips.filter(
      (ship) => !selectedShip.hasOwnProperty(ship.synmax_ship_id)
    );

    const attributedShips = shipsToFetch.filter(
      (ship) =>
        ship.type !== "unattributed" &&
        ship.type !== "AISSTS" &&
        ship.type !== "opticalSTS"
    );

    attributedShips.forEach((ship) => {
      const { synmax_ship_id, type, lat, lng, name, object_id, heading } = ship;
      updatedShip[synmax_ship_id] = {
        type,
        pathColor: ship?.path?.color,
        synmax_ship_id,
        ...(lat && lng ? { latitude: lat, longitude: lng } : {}),
        name,
        object_id,
        isSanctionedShip: vesselsData?.includes(synmax_ship_id),
        heading,
      };
    });

    const unattributedShips = shipsToFetch.filter(
      (ship) => ship.type === "unattributed"
    );
    unattributedShips.forEach((ship) => {
      const { synmax_ship_id, object_id, type, lat, lng, heading } = ship;
      updatedShip[synmax_ship_id] = {
        synmax_ship_id,
        object_id,
        type,
        heading,
        ...(lat && lng ? { latitude: lat, longitude: lng } : {}),
      };
    });

    const AISSTSShips = shipsToFetch.filter((ship) => ship.type === "AISSTS");
    AISSTSShips.forEach((ship) => {
      const {
        synmax_ship_id,
        synmax_ship_id_1,
        synmax_ship_id_2,
        type,
        lat,
        lng,
        name,
      } = ship;
      if (synmax_ship_id_1 && synmax_ship_id_2) {
        updatedShip[synmax_ship_id] = {
          synmax_ship_id,
          synmax_ship_id_1,
          synmax_ship_id_2,
          type,
          latitude: lat,
          longitude: lng,
          ship1_status: "AIS",
          ship2_status: "AIS",
          [synmax_ship_id_1]: {
            type,
          },
          [synmax_ship_id_2]: {
            type,
          },
        };
      }
    });

    const opticalSTSShips = shipsToFetch.filter(
      (ship) => ship.type === "opticalSTS"
    );
    opticalSTSShips.forEach((ship) => {
      const {
        synmax_ship_id,
        synmax_ship_id_1,
        synmax_ship_id_2,
        object_id_1,
        object_id_2,
        type,
        ship1_status,
        ship2_status,
        lat,
        lng,
        heading,
      } = ship;
      if (synmax_ship_id_1 && synmax_ship_id_2) {
        updatedShip[synmax_ship_id] = {
          synmax_ship_id,
          synmax_ship_id_1,
          synmax_ship_id_2,
          type,
          ship1_status,
          ship2_status,
          object_id_1,
          object_id_2,
          latitude: lat,
          longitude: lng,
          ship1Status: getShipStatusByType(ship1_status ? ship1_status : ""),
          ship2Status: getShipStatusByType(ship2_status ? ship2_status : ""),
          frontend_rotation: heading,
          [synmax_ship_id_1]: {
            type,
          },
          [synmax_ship_id_2]: {
            type,
          },
        };
      }
    });

    setSelectedShip((prevShips) => ({
      ...prevShips,
      ...Object.keys(updatedShip).reduce<{
        [key: string]: ShipDetailsInterFace | BunkeringInterface;
      }>((acc, key) => {
        const { synmax_ship_id_1, synmax_ship_id_2, type } = updatedShip[
          key
        ] as ShipDetailsInterFace;

        acc[key] = {
          ...(prevShips[key] || {}),
          ...updatedShip[key],
          ...(synmax_ship_id_1 && {
            [synmax_ship_id_1]: {
              ...(prevShips[key]?.[
                synmax_ship_id_1 as keyof (typeof prevShips)[typeof key]
              ] || {}),
              ...updatedShip[key][synmax_ship_id_1],
            },
          }),
          ...(synmax_ship_id_2 && {
            [synmax_ship_id_2]: {
              ...(prevShips[key]?.[
                synmax_ship_id_2 as keyof (typeof prevShips)[typeof key]
              ] || {}),
              ...updatedShip[key][synmax_ship_id_2],
            },
          }),
        };
        return acc;
      }, {}),
    }));
  };

  const fetchSatellites = async (
    mySatellite: {
      synmax_ship_id: string;
      name: string;
    }[],
    activeTabId: string | null
  ) => {
    if (!mySatellite || mySatellite.length === 0) return;
    const satellitesToFetch = mySatellite.filter(
      (satellite) =>
        !satelliteImageTimeline.hasOwnProperty(satellite.synmax_ship_id)
    );
    const activeTimelineInFetchList = mySatellite.find(
      (ship) => ship.synmax_ship_id === activeTabId
    );
    if (activeTimelineInFetchList && activeTabId) {
      const id = activeTimelineInFetchList.synmax_ship_id.slice(4);
      await shouldFetchSatelliteImage(id, activeTimelineInFetchList.name);
    }

    satellitesToFetch.forEach((satellite) => {
      const { synmax_ship_id, name } = satellite;
      if (satelliteImageTimeline.hasOwnProperty(synmax_ship_id)) return;
      setSatelliteImageTimeline(
        (prevState: { [key: string]: ShipImages[] }) => {
          const updatedState = { ...prevState };
          if (!updatedState[synmax_ship_id]) {
            updatedState[synmax_ship_id] = [
              {
                id: synmax_ship_id,
                name,
                object_id: -1,
                image: "",
              },
            ];
          }
          return updatedState;
        }
      );
    });
  };

  return (
    <ShipDetailsContext.Provider
      value={{
        detailsTabs,
        setDetailsTabs,
        shipDetailTabValue,
        setShipDetailTabValue,
        selectedShip,
        setSelectedShip,
        shipDetails,
        setShipDetails,
        fetchAndSetShipDetails,
        selectedEvent,
        setSelectedEvent,
        shipPaths,
        setShipPaths,
        handleShipDetailsTabChange,
        shipObjects,
        setShipObjects,
        bunkeringValue,
        setBunkeringValue,
        fetchAndSetShipPath,
        handleShipDetailsTabClose,
        fetchAndSetEventDetails,
        fetchEvents,
        fetchShipPaths,
        updateActiveStatus,
        setDarkEventOnMap,
        darkEventOnMap,
        collapsed,
        setSidebarCollapsed,
        shipCollapsed,
        setShipCollapsed,
        active,
        setActive,
        fetchShips,
        fetchSatellites,
      }}
    >
      {children}
    </ShipDetailsContext.Provider>
  );
};

export default useShipDetails;
