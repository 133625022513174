import axios from "axios";
import { api } from "../../../../services/api";
import { REACT_APP_BASE_URL } from "../../MainMap";

export interface WebcamHistory {
  docId: string;
  pulledAtUtc: string;
  sourceJobId: string;
  updatedAtUtc: string;
  url: string;
}

export const getWebcamImageHistory = async ({ camId, startDate, endDate }: { camId: string, startDate: string, endDate: string }):  Promise<WebcamHistory[]> => {
  const token = localStorage.getItem("token");
  try {
    const res = await api.get(`osIntel/webcams/${camId}/history?startDate=${startDate}&endDate=${endDate}`);
    return res.data;
  } catch(error: any) {
    throw new Error("error fetching Webcam thumbnail:", error);
  }
};

export const getWebcamFullImage = async ({ url }: { url: string }): Promise<Blob> => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get(
      `${REACT_APP_BASE_URL}/v1/osint/webcams/full-image?url=${url}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
        responseType: "blob"
      }
    );
    return res.data;
  } catch(error: any) {
    throw new Error("error fetching webcam image:", error);
  }
};