import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { RootState } from "./redux/store";
import Spinner from "./components/Spinner/Spinner";
import Routes from "./routes";
import SnackBar from "./components/SnackBar";
import useFireBaseAuth from "./context/useFireBaseAuth";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

export const REACT_APP_ENV = process.env.REACT_APP_ENV;

export const frontendAPIURL =
  REACT_APP_ENV === "dev"
    ? "https://frontend-api-j75nm34yfa-uc.a.run.app/"
    : "https://frontend-api-y5rlib2gfa-uc.a.run.app/";

export default function App() {
  const { handleAuthStateChange } = useFireBaseAuth();
  const { showLoader } = useSelector(
    (state: RootState) => state.globalTheiaState
  );

  useEffect(() => {
    handleAuthStateChange();
  }, []);

  return (
    <div className="App">
      {showLoader && <Spinner />}
      <SnackBar />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </div>
  );
}
